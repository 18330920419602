import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment-timezone';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HighRollersService {
    private subject:BehaviorSubject<Array<any>> = new BehaviorSubject<any>([]);

    private max_items = 50;
    timestamp!:number;
    constructor(
        private apiService: ApiService
    ) {
        this.onInit()
    }

    public getData() {
        return this.subject.asObservable() 
    }

    public clearData() {
        return this.subject.next([])
    }

    public setData(data:any) {
        if(!data || data.length == 0) return;

        const value = this.subject.getValue()
        data.map((v:any) => {
            if(!this.timestamp) this.timestamp = moment(v.createdAt).unix()
            else {
                const timestamp = moment(v.createdAt).unix()
                if(timestamp > this.timestamp) this.timestamp = timestamp
            }

            // max data
            if(value.length >= this.max_items) value.shift(); // 1개 삭제
            value.push(v)
        })

        this.subject.next(value)
    }

    private onInit() {
        this.apiService.get('/main/high_rollers').subscribe({
            next: (jsondata) => {
                if(jsondata.success) this.subject.next(jsondata.data.items)
            }, error: (err) => {
            }
        })
    }
}