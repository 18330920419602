import { ChangeDetectorRef, Component, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { ImgLoadedDirective } from '@app/shared/lib/directive/img.directive';
import { LoadingComponent } from "../../common/loading/loading.component";
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ImgxDirective } from '../../common/imgx.directive';

@Component({
  selector: 'app-game-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ImgLoadedDirective,
    LoadingComponent,
    ImgxDirective
],
  templateUrl: './game-list.component.html',
  styles: ``
})
export class GameListComponent {
    private subs: Subscription[] = [];

    @Input() tag: string = ''
    
    page: number = 1;
    items_total: number = 0;
    limit: number = 50;

    items_store:any = []
  
    category:any = {
        slot_lobby: `슬롯 로비`,
        freespin: `프리 스핀`,
        megaways: `메가웨이즈`,
        top_games: `인기게임`,
        new: `신규게임`,
        event_game: `이벤트게임`,
    }

    items_providers:any
    search_providers:Set<any> = new Set()
    orderBy:string = ''
    search_provider_text = `모든 제공업체`

    directMode!:boolean
    isLoading:boolean = true;
    vendor!:string
    platform:string = 'mobile';

    get percentage() { 
        if(this.items_total == 0 || this.items_store.length == 0) return 0;
        return Math.ceil((this.items_store.length / this.items_total) * 100)
    }
    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private mediaObserver: MediaObserver
    ) {}

    ngOnInit(): void {
        this.mediaObserver.asObservable().subscribe((changes:MediaChange[])=> {
            const platform = (changes[0].mqAlias === 'xs' || changes[0].mqAlias === 'sm') ? 'mobile' : 'pc';
            if(platform != this.platform) {
                this.platform = platform;
                // this.gameUrl = 'about:blank'
                // if(this.platform == 'pc') {
                //     this.isPlay = false;
                // }
            }
        })

        this.subs.push(
            this.activatedRoute.paramMap.subscribe(params => {
                const provider_idx = Number(params.get('provider_idx'))
                if(provider_idx) this.search_providers.add(provider_idx)
            })
        )

        this.subs.push(
            this.activatedRoute.data.subscribe((v:any) => {
                if(v.tag) {
                    this.directMode = true;
                    this.tag = v.tag || '';
                    this.fnGetProviders()
                } else {
                    this.directMode = false;
                }
            })
        );

        this.page = this.activatedRoute.snapshot.queryParams['page'] || 1;
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['tag']) {
            const prev = changes['tag'].previousValue;
            const current = changes['tag'].currentValue
            if(current) {
                this.tag = current
                this.directMode = false;
                this.fnGetProviders()
            }
        }
    }

    fnGetProviders(){
        this.isLoading = true;
        this.apiService.post(`/casino/providers`, { tags: this.tag, providers: [...this.search_providers].join(',')}).subscribe({
            next: (jsondata:any) => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.items_providers = jsondata.data.sort((a:any,b:any) => {
                        if(a.name > b.name) return 1;
                        else if(a.name < b.name) return -1;
                        else return 0
                    });

                    if(this.tag == 'provider') this.vendor = this.items_providers[0]['vendor']

                    this.fnGetData()
                }
            }, error: (e) => {
                this.isLoading = false;
            }
        })
    }

    fnGetData(): void {
        const params = {
            page: this.page || '',
            tags: this.tag || '',
            orderby: this.orderBy || '',
            providers: '',
            platform: this.platform
        }

        params.providers = [...this.search_providers].join(',')

        let url:string;
        if(this.vendor == 'WP') url = '/casino/wp_slots'
        else url = (this.tag == 'live_casino' || this.tag == `slot_lobby`) ? '/casino/lobby' : '/casino/games'
        this.isLoading = true;
        this.apiService.post(url, params).subscribe({
            next: (jsondata:any) => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.items_store = (this.page > 1) ? [ ...this.items_store, ...jsondata.data.items] : jsondata.data.items
                    this.items_total = jsondata.data.items_total;
                }
            }, error: (e) => {
                this.isLoading = false;
            }
        })
    }

    onProviders(idx:string, $event:any) {
        if(idx) {
            const checked = $event.target.checked
            if(checked) this.search_providers.add(idx)
            else this.search_providers.delete(idx)
        } else {
            this.search_providers.clear()
            this.items_providers.map((v:any) => v.checked = false)
        }

        this.search_provider_text= (this.search_providers.size) ? this.search_providers.size.toString() : `모든 제공업체`

        this.page = 1;
        this.fnGetData()
    }

    onOrderBy(orderBy:string) {
        this.page = 1;
        this.orderBy = orderBy
        this.fnGetData()
    }

    

    GoPage(page:any) {
        if(this.page === page) return;
        this.page = page;
        this.fnGetData()
    }

    GetPlayLink(tag:string, item:any) {
        let link:string;
        if(tag == 'slot_lobby') {
            link = '/slots/provider/' + item.idx 
        } else {
            if(this.vendor == 'WP') link = '/slots/wp-play/' + item.idx
            else link = '/slots/play/' + item.idx
        }

        return link;
    }
}
