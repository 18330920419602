import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'common-loading',
    standalone: true,
    imports: [
    CommonModule
    ],
    templateUrl: './common-loading.component.html',
    styles: `
.commonLoadingContainer {
    z-index:50; 
    position:fixed; 
    left:0; 
    top:0; 
    display:flex; 
    align-items:center; 
    justify-content:center; 
    width:100%; 
    height:100vh; 
    background-color: #24262B;
}    
    `,
})
export class CommonLoadingComponent {
    private subs: Subscription[] = [];

    constructor(
    ) {

    }

    ngOnInit(): void {


    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
