<div class="leading-relaxed p-1 sm:px-10 pb-6">
    <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
        <!-- 입금 -->
        <div>
            <div>1. 회원님의 소중한 충전금을 안전하게 보관하여 드립니다.</div>
            <div>2. 금고에 입금하신 금액은 언제든지 출금 가능 합니다.</div>
            <div>3. 금고 입/출금은 실시간으로 적용 됩니다.</div>
            <div class="flex flex-col items-center justify-center mt-2">
                <div class="text-yellow text-2xl font-semibold ">금고 보유금</div>
                <div class="text-white text-2xl font-semibold">{{ currentUser?.safebox | number }}원</div>
            </div>
            <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                <div class="flex items-center gap-4 text-base">
                    <p class="pl-4 text-tit">₩</p>
                    <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" numbersOnly />
                </div>
                <div class="text-primary px-1" *ngIf="currentUser">보유 금액: {{ currentUser.balance | number }}원</div>
            </div>
            <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                <div *ngIf="f['amount'].errors['required']">금액을 입력하세요.</div>
                <div *ngIf="f['amount'].errors['check_error']">{{f['amount'].errors['check_error']}}</div>
            </div>
            <div class="cash_btn_box mt-2">
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0);">초기화</button>
            </div>
            <div class="text-center mt-5">
                <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="isLoading">금고에 보관</button>
            </div>
        </div>
    </form>
</div>