import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './loading.component.html',
  styleUrls: [`./loading.component.css`],
})
export class LoadingComponent {
    private subs: Subscription[] = [];

    constructor(
    ) {
        
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
