
<ng-template [ngIf]="isLoading">
    <app-loading />
</ng-template>

<div class="bg-tab mx-1 p-2">
    <table class="table table-border mo_pad-s text-xs sm:text-sm">   
        <colgroup>
            <col width="10%">
            <col width="45%">
            <col width="30%">
            <col width="15%">
        </colgroup>
        <thead class="bg-back">
            <tr class="text-center">
                <th class="whitespace-nowrap !border-0 text-center">번호</th>
                <th class="whitespace-nowrap !border-0 text-center">제목</th>
                <th class="whitespace-nowrap !border-0 text-center">시간</th>
                <th class="whitespace-nowrap !border-0 text-center">상태</th>
            </tr>
        </thead>
        <tbody class="cursor-pointer">
            <ng-container *ngFor="let item of memo.items">
                <tr>
                    <td class="text-center text-tit" style="padding:.75rem .25rem;">{{ item['idx'] }}</td>
                    <td class="text-left text-tit" style="padding:.75rem .25rem;" (click)="read(item['idx'])">{{ item['subject']}}</td>
                    <td class="text-center" style="padding:.75rem .25rem;">{{ item['createdAt'] | moment:'YYYY-MM-DD HH:mm:ss'}}</td>
                    <td class="text-center text-tit" style="padding:.75rem .25rem;">
                        <span *ngIf="item['status'] != MEMO_STATUS.READ">{{MEMO_STATUS_TEXT[item['status']]}}</span>
                        <button (click)="MemoDelete(item['idx'])" class="btn-yellow px-2" *ngIf="item['status'] == MEMO_STATUS.READ">삭제</button>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="memo.items.length == 0">
                <tr>
                    <td colspan="4" class="text-center">
                        <img class="w-48 mx-auto" src="/assets/custom_img/empty.webp"><div class="-mt-5 text-basic opacity-70">Oops! 아직 데이터가 없습니다!</div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="flex justify-between items-center">
        <button (click)="read_all()" class="btn-green mt-2 px-3 py-2 font-extrabold" *ngIf="memo.items.length > 0">전체 읽음처리</button>
        <button (click)="MemoDelete('all')" class="btn-orange mt-2 px-3 py-2 font-extrabold" *ngIf="memo.items.length > 0">읽은쪽지 삭제</button>
    </div>
    
</div>

<app-pagination [page]="page" [limit]="10" [total]="memo.items_total" (pageChange)="GoPage($event)"></app-pagination>

