import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { CommonModule, DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-safebox-out',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    templateUrl: './safebox-out.component.html',
    styles: ``
})
export class SafeboxOutComponent {
private subs: Subscription[] = [];

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    currentUser!:currentUserInfo | null
    
    form1!: FormGroup;
    submitted:boolean = false;

    isLoading:boolean = false;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private apiService: ApiService,
        private decimalPipe: DecimalPipe,
        private router: Router
    ) {
        this.form1 = this.formBuilder.group({
            amount: ['0', Validators.required],
        });
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const safebox =  this.currentUser?.safebox || 0
                    let value = form.amount.toString().replace(/[^0-9]/g,"");
                    if(Number(value) > safebox) value = safebox;
                    // const amount = value.length > 4 ? Math.floor(Number(value)/10000) * 10000 : value; // 만원단위 절사
                    // this.f['amount'].patchValue(this.decimalPipe.transform(amount), { emitEvent: false });
                    this.f['amount'].patchValue(value, { emitEvent: false });
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    onSubmit() {
        this.isLoading = true;
        this.apiService.post('/safebox/out', this.form1.value).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) this.onClose.emit('success')
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = (+value) + amount;
        const safebox =  this.currentUser?.safebox || 0

        // total = Math.floor(total/10000) * 10000; // 만원단위 절사
        if(total > safebox) total = safebox;
        this.f['amount'].patchValue(total)
    }
}
