<!-- 입금 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <ng-template [ngIf]="isLoading">
        <app-loading />
    </ng-template>
    <!-- 기본 -->
    <div class="modal-body relative" >
        <div class="relative px-5 bg-modaldark rounded-t">
            <div class="flex items-center justify-between py-3">
                <p class="text-tit font-extrabold text-base">금고</p>
                <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
        <div class="px-2 pb-3">
            <ul class="nav nav-boxed-tabs bg-slate-200 dark:bg-back" role="tablist">
                <li class="nav-item w-full">
                    <button class="w-full h-9" [ngClass]="{'active': tab == 'in'}" type="button" role="tab" (click)="tab = 'in'">입금</button>
                </li>
                <li class="nav-item w-full">
                    <button class="w-full h-9" [ngClass]="{'active': tab == 'out'}" type="button" role="tab" (click)="tab = 'out'">출금</button>
                </li>
            </ul>
        </div>
        <div class="tab-content overflow-y-auto scrollbar h-auto text-sub">
            <ng-template [ngIf]="tab == 'in'">
                <app-safebox-in (onClose)="onClose.emit($event)" />
            </ng-template>
            <ng-template [ngIf]="tab == 'out'">
                <app-safebox-out (onClose)="onClose.emit($event)" />
            </ng-template>
        </div>
    </div>
</div>