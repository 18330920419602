<ng-template [ngIf]="isLoading">
    <app-loading />
</ng-template>
<div class="overflow-y-auto scrollbar h-auto sm:h-[680px] text-sub">
    <div class="leading-relaxed p-6 sm:px-10" >
        <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress_item">
            <div class="flex-1">입금신청이 진행중 입니다.</div>
            <!-- <button type="button" class="btn btn-purple2 p-1" (click)="deposit_cancel()">취소하기</button> -->
        </div>
        <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
            참고: <span class="text-tit">입금주소가 매번 변경됩니다. 입금주소를 꼭 확인하세요.</span>
        </div>
        <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
            주의사항: <span class="text-tit">KBC GAME에서 다이렉트로 업비트나 빗썸 등 거래소로 전송 하지마세요. <br />KBC GAME > 개인지갑 > 거래소 순서로 이용하시길 권장합니다.</span>
        </div>
        <div>입금화폐</div>
        <div class="flex flex-wrap gap-1 items-center mt-1 font-medium">
            <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="selected_crypto_code('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/USDT.webp" /> USDT</button>
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="buttonCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/TRX.webp" /> TRX</button> -->
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="selected_crypto_code('XRP')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/XRP.webp" /> XRP</button> -->
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="buttonCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/ETH.webp" /> ETH</button> -->
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="buttonCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BTC.webp" /> BTC</button> -->
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="buttonCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BNB.webp" /> BNB</button> -->
            <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="buttonCrypto.click()">더보기 <img class="w-3.5 ml-1" src="/assets/custom_img/coin/TRX.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/DOGE.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/LTC.webp" /> <svg class="w-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button> -->
        </div>
        <div class="md:flex items-center justify-between gap-1">
            <div class="flex-1">
                <div class="mt-3">Deposit Currency</div>
                <div class="ng-select2 flex-1 mt-3" ng-select>
                    <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark" #buttonCrypto>
                        <div class="flex items-center gap-3 text-tit name text-base ">
                            <img class="w-7" [src]="'/assets/custom_img/coin/' + f['code'].value +'.webp'" />
                            {{ f['code'].value }}
                        </div>
                        <div class="flex items-center gap-1">
                            <!-- 잔고현황 -->
                            <i class="ml-2"><svg class="w-3.5 h-3.5 fill-basic arr"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                        </div>
                    </button>
                    <div class="bg-white dark:bg-back2 shadow-lg">
                        <div class="p-4 pb-2">
                            <div class="relative w-full">
                                <label>
                                    <svg class="absolute left-3 top-2.5 w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg>
                                    <input type="text" class="form-control bg-modaldark pl-10" placeholder="검색" [(ngModel)]="keyword" />
                                </label>
                            </div>
                        </div>
                        <ul class="overflow-y-auto scrollbar h-64 p-2 rounded text-sub">
                            <li class="p-2 px-4 flex items-center justify-between" *ngFor="let item of crypto_codes | filter:'code':keyword:true" (click)="change_code($event, item)" [ngClass]="{'on':item.code == f['code'].value}">
                                <div class="flex items-center gap-2 text-tit font-extrabold name">
                                    <img class="w-7" [src]="'/assets/custom_img/coin/' + item.code +'.webp'" />
                                    {{ item.code }}
                                </div>
                                <div class="text-right text-xs">
                                    <!-- <b class="block text-tit text-sm">{{currentUser?.Wallets | wallet:item.code || '0.0000'}}</b> -->
                                    <!-- {{currentUser?.wallets[item.code]}} -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div class="mt-3">Choose Network</div>
                <div class="ng-select flex-1 mt-3" ng-select>
                    <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark">
                        <span>{{ f['network'].value }}</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2" *ngFor="let item of crypto_networks" (click)="change_network($event, item)">{{ item.value }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
            <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                <div class="flex items-center gap-4 text-base">
                    <p class="pl-4 text-tit">{{ f['code'].value }}</p>
                    <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit " placeholder="" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" numbersOnly />
                </div>
                <div class="text-primary">
                    <button type="button" class="btn-primary px-1" (click)="check_currency_rate()">예상금액조회</button>
                </div>
            </div>
            <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                <div *ngIf="f['amount'].errors['required']">금액을 입력하세요.</div>
                <div *ngIf="f['amount'].errors['check_error']">{{f['amount'].errors['check_error']}}</div>
            </div>
            <div class="mt-4 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="expected_amount && expected_rate">
                <div class="flex items-center">
                    <span>예상금액: </span>
                    <span class="text-yellow mr-1">{{ expected_amount | number }}원</span>
                    <span class="ml-1">적용환율: </span>
                    <span class="text-tit">{{ expected_rate | number }}원</span>
                    <div class="tooltip_custom ml-1">
                        <svg class="inline-flex w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                        <div class="hover_box top w-96 shadow p-2 rounded bg-back">
                            <p class="text-tit">실제 입금시점의 적용환율은 달라질 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center mt-3" *ngIf="is_promotion">
                <div class="flex items-center gap-2">
                    <label class="form-check-label ml-0" for="wallet-0"><b class="text-tit">{{ deposit_rolling_text }}</b> 이상 롤링에 동의하며 <span class="text-tit">최소 {{ min_deposit_usdt | number:'1.0-2' }} USDT를 입금하고, {{ deposit_mileage_rate | number:'1.0-2' }}% (최대:{{ deposit_mileage_max | number }}원)</span> 보너스를 받겠습니다. </label> 
                </div>
            </div>
            <div class="text-center mt-10">
                <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress_item">입금주소확인</button>
            </div>
        </form>
        <div class="bg-[#f5f6fa] dark:bg-[#1b1c1f] flex gap-4 p-4" *ngIf="in_progress_item">
            <img class="w-36" src="" [alt]="in_progress_item.address" qrcode />
            <div>
                <div class="flex">
                    <span class="text-tit">{{ in_progress_item.wallet_code }}</span>
                </div>
                <div class="flex">
                    <span class="text-yellow">{{ in_progress_item.crypto_currency }}</span>
                    <span class="text-tit ml-1">네트워크</span>
                    <span class="text-yellow ml-1">{{ in_progress_item.chain_info }}</span>
                    <span class="text-tit ml-2">입금시간</span>
                    <span class="text-yellow ml-1" remain [time]="in_progress_item.expiredAt" (expire)="expired()"></span>
                </div>
                <div class="flex items-center gap-2 bg-back2 py-3 px-2">
                    <p class="break-all">{{ in_progress_item.address }}</p>
                    <button type="submit" class="btn-normal basic-hover w-24 h-10 rounded" (click)="copyAddress(in_progress_item.address)">복사 하기</button>
                </div>
                <div class="text-primary" *ngIf="copyMessage">{{copyMessage}}</div>
            </div>
        </div>
    </div>
</div>