import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-iframe-modal',
    standalone: true,
    imports: [
        SanitizePipe
    ],
    templateUrl: './iframe-modal.component.html',
    styles: `
.game-box { width:100%; height:100%;text-align:center; }
.m-body { 
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: none;
}
.m-body::-webkit-scrollbar {
    display: none
}

.iframe-full { width:100%;height:100%;object-fit:cover;}

@media(min-width: 1200px) {
    .iframe-full {
        height:100%;
        width: 25%;
    }
}    
    `
})
export class IframeModalComponent {
    private subs: Subscription[] = [];
    
    @Input() options:any;
    @Input() eventEmitter:EventEmitter<any> = new EventEmitter<any>()

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();



    @ViewChild('iframeEl') iframeEl!: ElementRef;

    constructor() {
        
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['eventEmitter']) {
            const prev = changes['eventEmitter'].previousValue;
            const current = changes['eventEmitter'].currentValue
            if(current) {
                this.subs.push(
                    this.eventEmitter.subscribe(v => {
                        const { event } = v
                        if(event == 'close_iframe') this.onClose.emit('close')
                    })
                )
            }
        }
    }

    iframeLoad($event:any): void {

    }
}
