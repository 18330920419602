<div  class="text-xl text-tit font-medium">최신 베팅&레이스</div>
                                        
<ul class="nav nav-boxed-tabs !w-auto mt-2" role="tablist">
    <li id="batting-1-tab" class="nav-item bg-sponsor rounded-l overflow-hidden" role="presentation"> 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='latest_bets'}"  type="button" (click)="onTab('latest_bets')"> 최근베팅 </button> 
    </li>
    <li id="batting-2-tab" class="nav-item bg-sponsor " role="presentation"> 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='high_rollers'}" type="button" (click)="onTab('high_rollers')"> 하이롤러 </button> 
    </li>
    <li id="batting-3-tab" class="nav-item bg-sponsor rounded-r overflow-hidden" role="presentation" > 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='wager_contest'}" type="button" (click)="tab='wager_contest'"> 롤링 대회 </button> 
    </li>
</ul>
<div class="tab-content mt-5">
    <div class="tab-pane leading-relaxed p-5 bg-sponsor" [ngClass]="{'active': tab == 'latest_bets'}" role="tabpanel" >
        <div class="overflow-x-auto scrollbar_x overflow-y-hidden">
            <div class="grid grid-cols-4 gap-2 rounded" style="min-width: 780px;">
                <div class="text-center">게임</div>
                <div class="text-center">플레이어</div>
                <div class="text-center">베팅금액</div>
                <!-- <div class="text-center">배당</div> -->
                <div class="text-right">혜택 금액</div>
            </div>
            <div style="min-width: 780px;">
                <ng-container *ngFor="let item of latestBetItems; let i = index;">
                    <div class="grid grid-cols-4 gap-2 justify-between rounded p-1" style="min-width: 780px;">
                        <div class="text-left" ><img class="inline-flex mr-1 w-6" [src]="GetGamesIcon(item['bet_code'], item['game_name'])" /> {{ item['game_name'] | ellipsis:10 }}</div>
                        <div class="text-left"><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: item.uidx })">{{ item.nick }}</b></div>
                        <div class="text-right"><b [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0 && item['status'] != 'IN_PROGRESS', 'text-tit': item['status'] == 'IN_PROGRESS'}">{{ item.bet_amount | number:'1.0-2' }}</b><img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/KRW.webp'" /></div>
                        <!-- <div class="text-center" [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0  && item['status'] != 'IN_PROGRESS'}">{{ item['hit_rate'] | number:'1.2' }}x</div> -->
                        <div class="text-right">
                            <span [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0}" *ngIf="item['status'] != 'IN_PROGRESS'">
                                {{ item['hit_amount'] | number:'1.0-2' }}<img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/KRW.webp'" />
                            </span>
                            <span *ngIf="item['status'] == 'IN_PROGRESS'">진행중</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- <button class="btn h-8 flex px-4 mt-4 mx-auto items-center justify-center border-none bg-back gap-2">
            <span>더 보기</span>
            <i><svg class="w-3.5 h-3.5 fill-basic rotate-90"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
        </button> -->
    </div>
    <div class="tab-pane leading-relaxed p-5 bg-sponsor" [ngClass]="{'active': tab == 'high_rollers'}" role="tabpanel">
        <div class="overflow-x-auto scrollbar_x ">
            <div class="grid grid-cols-4 gap-2 rounded" style="min-width: 780px;">
                <div class="text-center">게임</div>
                <div class="text-center">플레이어</div>
                <div class="text-center">베팅금액</div>
                <!-- <div class="text-center">배당</div> -->
                <div class="text-right">혜택 금액</div>
            </div>
            <div style="min-width: 780px;">
                <ng-container *ngFor="let item of highRollersItems; let i = index;">
                    <div class="grid grid-cols-4 gap-2 justify-between rounded p-1" style="min-width: 780px;">
                        <div class="text-left" ><img class="inline-flex mr-1 w-6" [src]="GetGamesIcon(item['bet_code'], item['game_name'])" /> {{ item['game_name'] | ellipsis:10 }}</div>
                        <div class="text-left"><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: item.uidx })">{{ item.nick }}</b></div>
                        <div class="text-right"><b [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0 && item['status'] != 'IN_PROGRESS', 'text-tit': item['status'] == 'IN_PROGRESS'}">{{ item.bet_amount | number:'1.0-2' }}</b><img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/KRW.webp'" /></div>
                        <!-- <div class="text-center" [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0  && item['status'] != 'IN_PROGRESS'}">{{ item['hit_rate'] | number:'1.2' }}x</div> -->
                        <div class="text-right">
                            <span [ngClass]="{'text-danger': item['hit_amount'] > 0, 'text-primary': item['hit_amount'] == 0}" *ngIf="item['status'] != 'IN_PROGRESS'">
                                {{ item['hit_amount'] | number:'1.0-2' }}<img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/KRW.webp'" />
                            </span>
                            <span *ngIf="item['status'] == 'IN_PROGRESS'">진행중</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- <button class="btn h-8 flex px-4 mt-4 mx-auto items-center justify-center border-none bg-back gap-2">
            <span>더 보기</span>
            <i><svg class="w-3.5 h-3.5 fill-basic rotate-90"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
        </button> -->
    </div>
    <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'wager_contest'}"  role="tabpanel" aria-labelledby="batting-3-tab">
        <app-promotion-contest />
    </div>
</div>