<div class="container_wrap">

    <div class="container md:!px-10 !px-0 pt-0 md:pt-6">

        <!-- 비주얼 -->
        <div class="vip_visual flex md:flex-row flex-col items-center h- md:h-80">
            <div class="w-full h-full p-5 md:pl-12 flex flex-col md:justify-center justify-between">
                <div class="md:text-left text-center">
                    <h3 class="text-tit text-3xl xl:text-4xl font-extrabold">독점적인 <span class="text-yellow">VIP</span> 혜택</h3>
                    <p class="mt-3 text-tit md:w-3/5 font-medium">지금 VIP 클럽에 가입하고 선물, 사은품 및 놀라운 기능을 받을 준비를 하십시오! 당신을 놀라게 할 더 높은 캐쉬백과 독점적인 보상의 스릴을 경험하세요.</p>
                </div>
                <div class="flex gap-2 mt-7">
                    <a routerLink="/casino" class="flex items-center justify-center w-48 h-12 btn-green font-bold" >게임 시작</a>
                    <button class="w-48 h-12 border border-slate-900 text-tit font-bold rounded" (click)="openModal($event, 'vip-club')">VIP 클럽</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 vip_con01 my-10">
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_01.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">수수료없는 출금</h4>
                <p class="mt-1 text-xs">가상화폐 출금시 모든 레벨에서 수수료 없는 인출 특전을 누리세요.</p>
            </div>
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_02.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">럭키 스핀 보상</h4>
                <p class="mt-1 text-xs">플래티넘에서 전담 컨설턴트의 개인화된 관심과 독점적인 혜택을 즐기십시오.</p>
            </div>
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_03.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">VIP 전담 컨설턴트</h4>
                <p class="mt-1 text-xs">플래티넘에서 전담 컨설턴트의 개인화된 관심과 독점적인 혜택을 즐기십시오.</p>
            </div>
            <div class="type02 rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_04.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">놀라운 레벨 업 보너스</h4>
                <p class="mt-1 text-xs">플래티넘에서 전담 컨설턴트의 개인화된 관심과 독점적인 혜택을 즐기십시오.</p>
            </div>
            <div class="type02 rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_05.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">재미있는 팁과 레인</h4>
                <p class="mt-1 text-xs">플래티넘에서 전담 컨설턴트의 개인화된 관심과 독점적인 혜택을 즐기십시오.</p>
            </div>
            <div class="type02 rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_hidden.png" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">히든 보너스</h4>
                <p class="mt-1 text-xs">다이아몬드에서 매월 지급되는 특별 보너스의 혜택을 즐기십시오.</p>
            </div>
        </div>

        <div class="">
            <h4 class="text-tit text-2xl font-bold mb-1">VIP 보상 내역</h4>
            <div class="bg-backlight p-3 rounded overflow-x-auto">
                <table class="table pad-s table-border table-border-type text-tit min-w-[800px]">
                    <colgroup>
                        <col width="8%">
                        <col width="9%">
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                    </colgroup>
                    <thead>
                        <tr class="bg-back text-center font-bold">
                            <th colspan="2"></th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/bronze.png" alt="">브론즈</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/silver.png" alt="">실버</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/gold.png" alt="">골드</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/platinum.png" alt="">플래티넘I</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/platinum.png" alt="">플래티넘II</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/diamond.png" alt="">다이아몬드I</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/diamond.png" alt="">다이아몬드II</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center">
                            <td rowspan="2" >실시간</td>
                            <td>회원롤링 %</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                            <td>0.75</td>
                            <td>0.8</td>                            
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >스포츠</td>
                            <td>회원롤링 %</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                            <td>0.75</td>
                            <td>0.8</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >카지노</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.2</td>
                            <td>0.2</td>
                            <td>0.25</td>
                            <td>0.3</td>
                            <td>0.3</td>
                            <td>0.35</td>
                            <td>0.4</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >슬롯게임</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.3</td>
                            <td>0.35</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >미니게임</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.3</td>
                            <td>0.35</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">최대 베팅 금액 %</td>
                            <td>100</td>
                            <td>120</td>
                            <td>150</td>
                            <td>200</td>
                            <td>200</td>
                            <td>200</td>
                            <td>200</td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">레벨업 룰렛 티켓</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-silver mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-gold mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">전용 이벤트</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-gold mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">전담 컨설던트</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">충환전 우선처리</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>  
                        <tr class="text-center">
                            <td colspan="2">히든 보너스</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>



    <div class="bg-btndark py-10 ">
        <div class="container">
            <h4 class="text-tit text-2xl font-bold mb-1">자주 묻는 질문</h4>

            <div class="accordial_list mt-2 p-5 bg-backlight rounded">
                <div class="accordian_item">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '레벨업 보너스'란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        플레이어에 대한 감사의 표시로 다음 레벨에 도달하면 레벨 업 보너스로 보상합니다. 
                        레벨업 보너스는 레벨별 축하금과 함께 럭키스핀 크레딧을 제공합니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '레인'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">                  
                        룰:<br/>
                        <ol class="list-decimal pl-5">
                            <li>3레벨 이상의 플레이어 6명을 무작위로 선택하여 24시간마다 공지방에서 보상을 드립니다.</li>
                            <li>보너스는 통화에 따라 다릅니다.</li>
                            <li>플레이어는 레인 알고리즘에 의해 무작위로 선택됩니다. 더 많은 베팅을 한 플레이어는 보상을 받을 확률이 더 높습니다.</li>
                            <li>그러나 사용자가 베팅을 한번만 한 경우에도 여전히 레인 보너스를 받을 수 있는 기회가 있습니다.</li>
                            <li>레인 정보는 로봇에 의해 공지방으로 전송됩니다.</li>
                            <li>사용자당 하나 이상의 계정을 갖는 것은 금지됩니다. 레인을 받을 가능성이 낮아지고, KBC는 최대 24시간이 소요될 수 있는 사기 방지 시스템의 수동 검토를 위해 인출을 중단합니다.</li>
                            <li>우리는 인출 처리를 보장하지 않으며 잔액이 있는 계정을 금지할 수 있습니다. 이미 두 개 이상의 계정이 있는 경우 라이브 지원에 연락하여 스스로 포기하십시오.</li>
                        </ol>
                        <p class="text-tit">예를들어,:</p>
                        <div class="mt-2 inline-flex">
                            <div class="mt-2 flex gap-2 rounded bg-backlight p-3">
                                <i class="block w-9 h-9 rounded-full overflow-hidden"><img src="/assets/custom_img/profile_img.png" alt=""></i>
                                <div>
                                    <div class="py-2">KBC VIP 상담원<svg class="w-4 h-4 inline-flex fill-blue-500"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Official"></use></svg></div>
                                    <div class="bg-sponsor rounded p-3">
                                        <p class="text-tit">비가 오는 중이네요, 비를 맞은 사람들:</p>
                                        <p class="text-primary text-xs">&#64;Love1004: 111,111 KRW<br/>&#64;BetAngel: 1,111 USDT<br/>&#64;king2king: 11,111 KRW<br/>&#64;Bitbare: 11 USDT<br/>&#64;slotisBack: 1,111 KRW<br/>&#64;Louis: 111 USDT</p>
                                        <p class="text-tit">축하합니다!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '코인드랍'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        KBC GAME은 가상화폐 입출금을 하시는 모든 사용자에게 코인드롭의 기회를 제공합니다.<br/>
                        코인드롭 이벤트 발생 24시간 전 공지사항을 통해 이벤트 내용이 공지됩니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '팁'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        KBC GAME을 사용하는 모든 플레이어는 고객센터를 통해 담당 어카운트 매니저에게 감사를 표시할 수 있습니다. 친절한 안내에 따른 보낼 금액을 결정할 수 있습니다.
                    </div>
                </div>
                
            </div>
        </div>

    </div>




    
    <!-- ** 푸터 시작 ** -->
    <!-- ** 푸터 끝 ** -->

</div>