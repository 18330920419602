import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ApiService } from '../../service/api.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { LoadingComponent } from '../../common/loading/loading.component';
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';

@Component({
    selector: 'app-withdraw-crypto',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MomentPipe,
        LoadingComponent,
        NgSelectDirective
    ],
    templateUrl: './withdraw-crypto.component.html',
    styles: ``
})
export class WithdrawCryptoComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    currentUser!:currentUserInfo | null

    form1!: FormGroup;
    submitted = false;

    rolling_item:any;
    in_progress:any
    min_withdraw:number = 0;
    crypto_addrsss_items:any;

    isLoading:boolean = false;

    // 출금주소
    selected_item:any;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private decimalPipe: DecimalPipe,
        private authService: AuthService,
        private apiService: ApiService,
    ) {
        this.form1 = this.formBuilder.group({
            amount: ['0', Validators.required],
            crypto_address_idx: ['', Validators.required],
        });
    }

    ngOnInit(){
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    const amount = value.length > 4 ? Math.floor(Number(value)/10000) * 10000 : value; // 만원단위 절사
                    // this.f['amount'].patchValue(this.decimalPipe.transform(amount), { emitEvent: false });
                    this.f['amount'].patchValue(amount, { emitEvent: false });
                }
            })
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get("/withdraw/info-withdraw-crypto").subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.min_withdraw = Math.floor(jsondata.data.min_withdraw);
                    this.in_progress = jsondata.data.history;
                    this.rolling_item = jsondata.data.rolling_item;
                    this.crypto_addrsss_items = jsondata.data.address;
                    if(this.crypto_addrsss_items && this.crypto_addrsss_items.length > 0) {
                        this.selected_item = this.crypto_addrsss_items[0]
                        this.f['crypto_address_idx'].patchValue(this.selected_item.idx)
                    }
                }

                if(jsondata.message) (window as any).alert(jsondata.message);
            }, error: (err) => {
                this.isLoading = false;
            }
        });
    }

    onSubmit(){
        this.submitted = true;

        const value = Number(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(value <= 0) {
            this.f['amount'].setErrors({ 'check_error': `출금액을 입력하세요.` });
            return;
        }

        const amount = Math.floor(value/10000) * 10000; // 만원단위 절사

        if(amount < this.min_withdraw) {
            this.f['amount'].setErrors({ 'check_error': `최소 출금액은 ${ this.decimalPipe.transform(this.min_withdraw) }원 입니다.` });
            return;
        }

        const balance = this.currentUser?.balance
        if(amount >  Number(balance)) {
            this.f['amount'].setErrors({ 'check_error': `보유금액은 ${ this.decimalPipe.transform(balance) }원 입니다.` });
            return;
        }

        if(!this.f['crypto_address_idx'].value) {
            this.f['crypto_address_idx'].setErrors({ 'check_error': `출금주소를 선택하세요.` });
            return;
        }

        this.f['amount'].patchValue(amount);

        this.isLoading = true;
        this.apiService.post('/withdraw/process', this.form1.value).subscribe({
            next: jsondata => {
                this.isLoading = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) this.in_progress = jsondata.data.history;
            }, error: err => {
                this.isLoading = false;
                this.submitted = false;
            }
        })
    }

    withdraw_cancel() {
        this.isLoading = true;
        this.apiService.post('/withdraw/cancel', { idx: this.in_progress['idx']}).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) this.in_progress = null
                if(jsondata.message) (window as any).alert(jsondata.message)

            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = Number(value) + amount;
        if(this.currentUser) {
            const balance = Math.floor(this.currentUser.balance)
            if(total > balance) total = balance;
    
            const amount = Math.floor(total/10000) * 10000; // 만원단위 절사
            this.f['amount'].patchValue(amount)
        }
    }

    change_address($event:any, item:any) {
        if($event) $event.preventDefault();
        const el = $event.target
        const parent = el.closest('.ng-select'); // style.scss
        if(parent) parent.classList.remove('on')

        this.f['crypto_address_idx'].patchValue(item.idx)
        this.selected_item = item;
    }

    current_rolling() {
        if(!this.rolling_item) return 0;
        if(this.rolling_item.rolling_times == 0) return 100; // 롤링해야 할 금액이 없으면 100%

        const rolling_deposit = Number(this.rolling_item.rolling_deposit)
        const rolling_bonus_amount = Number(this.rolling_item.rolling_bonus_amount)
        const rolling_times = Number(this.rolling_item.rolling_times)
        const rolling_sports = Number(this.rolling_item.rolling_sports)
        const rolling_sports_one =  Number(this.rolling_item.rolling_sports_one)
        const rolling_betradar =  Number(this.rolling_item.rolling_betradar)
        const rolling_minigame =  Number(this.rolling_item.rolling_minigame)
        const rolling_slot =  Number(this.rolling_item.rolling_slot)
        const rolling_wp =  Number(this.rolling_item.rolling_wp)
        const rolling_eeze =  Number(this.rolling_item.rolling_eeze)
        const rolling_casino =  Number(this.rolling_item.rolling_casino)

        const sports_rate = ((rolling_sports + rolling_betradar + rolling_minigame + rolling_slot + rolling_wp + rolling_eeze) / (rolling_deposit + rolling_bonus_amount)) * 100;
        let sports_one_rate = (rolling_sports_one / rolling_deposit) * 100;
        let casino_rate = (rolling_casino / rolling_times) * 100;

        if(this.rolling_item.bonus_amount > 0) { // 보너스 롤링이면
            sports_one_rate = sports_one_rate / 2;
            casino_rate = casino_rate / 3
        }

        return sports_rate + sports_one_rate + casino_rate;
    }

    GetMinigameRolling() {
        return Number(this.rolling_item['rolling_minigame']) + Number(this.rolling_item['rolling_wp'])
    }
}
