import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { LoadingComponent } from "../../../../admin/src/common/loading/loading.component";
import { Router } from '@angular/router';

@Component({
    selector: 'app-deposit-currency',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LoadingComponent
    ],
    templateUrl: './deposit-currency.component.html',
    styles: ``
})
export class DepositCurrencyComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    currentUser!:currentUserInfo | null

    form1!: FormGroup;
    submitted = false;
    submitted2 = false; // 입금계좌 확인
    sigining = false;

    bonus_type:any;
    is_show_charge: boolean = false; // 입금계좌 보여주는 여부
    is_first_deposit: boolean = false;  // 첫 충전 여부
    is_promotion:boolean = false; // 프로모션 입금

    min_deposit:number = 0;
    deposit_mileage_max:number = 0; // 입금보너스 맥스
    deposit_mileage_rate:number = 1; // 입금보너스 rate
    deposit_rolling_rate:number = 100;  // 보너스 롤링 rate
    first_deposit_mileage_rate: number = 1; // 첫충전 rate
    deposit_rolling_text:string=''

    charge_bank_name!:string; // 입금계좌 은행
    charge_bank_account!:string; // 입금계좌 번호
    charge_bank_owner!:string; // 입금계좌 예금주

    in_progress!:number | null; // 진행중인 입금 

    bonus_amount: number = 0; // 보너스 금액
    bonus_rate:number = 0;

    isLoading:boolean = false;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private apiService: ApiService,
        private decimalPipe: DecimalPipe,
        private router: Router
    ) {
        this.form1 = this.formBuilder.group({
            promotion_code: [''],
            amount: ['0', Validators.required],
            is_bonus_rolling: [false, Validators.required ],
            bonus_code:['1', Validators.required],
            pwd: [''],
        });
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    const amount = value.length > 4 ? Math.floor(Number(value)/10000) * 10000 : value; // 만원단위 절사
                    // this.f['amount'].patchValue(this.decimalPipe.transform(amount), { emitEvent: false });
                    this.f['amount'].patchValue(amount, { emitEvent: false });
                }
            })
        )

        this.fnGetData();
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['options']) {
            const prev = changes['options'].previousValue;
            const current = changes['options'].currentValue
            if(current) {
                if(current['promotion_code']) {
                    this.is_promotion = true;
                    this.form1.controls['promotion_code'].patchValue(current['promotion_code'])
                    this.form1.controls['is_bonus_rolling'].patchValue(true)
                    this.form1.controls['is_bonus_rolling'].disable() // 프로모션 입금이면 롤링을 무조건
                }
            }
        }
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.post("/deposit/info-deposit",  { promotion_code: this.form1.controls['promotion_code'].value }).subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.bonus_type = jsondata.data['bonus_type']

                    this.bonus_type.map((v:any) => {
                        if(v.code == '3') {
                            this.deposit_mileage_rate = Number(v['bonus_rate']); // 입금보너스 RATE
                            this.deposit_rolling_rate = Number(v['rolling_rate']); // 입금보너스 롤링 RATE
                            this.deposit_rolling_text = v['text'];
                        }
                    })

                    this.min_deposit = Number(jsondata.data['min_deposit'])
                    this.is_first_deposit = jsondata.data['is_first_deposit'] ? true : false; // 첫 충전 여부
                    this.first_deposit_mileage_rate = Number(jsondata.data['first_deposit_mileage_rate']) // 첫 충전 RATE
                    this.deposit_mileage_max = Number(jsondata.data['deposit_mileage_max'])
                    if(this.deposit_mileage_max == 0) {
                        this.form1.controls['is_bonus_rolling'].disable()
                    }
                    
                    this.roll_change_check();

                    // 진행중인 입금
                    this.in_progress = jsondata.data['in_progress']

                } else {
                    this.onClose.emit('faild')
                    if(jsondata.message) (window as any).alert(jsondata.message)
                    setTimeout(() => {
                        this.onClose.emit('faild')
                    }, 1000);
                }
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    onSubmit() {
        this.submitted = true;
        this.submitted2 = true;

        const value = Number(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        const amount = Math.floor(value/10000) * 10000; // 만원단위 절사

        if(amount < this.min_deposit) this.f['amount'].setErrors({ 'check_error': `최소 입금액은 ${ this.decimalPipe.transform(this.min_deposit) } 원 입니다.` });
        if(!this.is_show_charge) {
            this.f['pwd'].setErrors({ 'check_error': '입금계좌를 먼저 확인하세요.'})
            return;
        }
        if(this.form1.invalid) {
            console.log(this.form1)
            return;
        }

        this.f['amount'].patchValue(amount);
        this.isLoading = true;
        this.apiService.post('/deposit/process', this.form1.getRawValue()).subscribe({
            next: jsondata => {
                this.isLoading = false;
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    this.in_progress = jsondata.data['in_progress'];
                    // this.onClose.emit('close')
                }
            }, error: err => {
                this.isLoading = false;
            }
        })
    }


    deposit_cancel() {
        this.isLoading = true;
        this.apiService.post('/deposit/cancel', { idx: this.in_progress }).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) this.fnGetData();
                if(jsondata.message) (window as any).alert(jsondata.message)
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    fnGetAccount() {
        this.submitted2 = true;
        if(!this.f['pwd'].value) {
            this.f['pwd'].setErrors({ 'check_error': `입금계좌를 확인하려면 비밀번호를 입력하세요.` });
            return;
        }

        this.isLoading = true;
        this.apiService.post('/deposit/ask-bank', { pwd: this.f['pwd'].value}).subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.charge_bank_account = jsondata.data.bank_account
                    this.charge_bank_name = jsondata.data.bank_name
                    this.charge_bank_owner = jsondata.data.bank_owner

                    this.is_show_charge = true
                    this.form1.controls['pwd'].updateValueAndValidity();
                    // this.f['pwd'].setErrors({ 'check_error': null });

                } 

                if(jsondata.message) (window as any).alert(jsondata.message);
                
            }, error: err => {
                this.isLoading = false;
            }
        })
    }


    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = (+value) + amount;

        total = Math.floor(total/10000) * 10000; // 만원단위 절사
        this.f['amount'].patchValue(total)
        this.fnSetBonus()
    }

    fnSetBonus() {
        if(this.bonus_rate <= 0) {
            this.bonus_amount = 0
            return;
        };

        if(this.f['is_bonus_rolling'].value !== true ) {
            this.bonus_amount = 0
            return;
        }
        
        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(!value || value == 0) {
            this.bonus_amount = 0
            return;
        }

        

        let bonus = Math.floor(value * this.bonus_rate / 100)
        if(!bonus) bonus = 0;
        if(bonus > this.deposit_mileage_max) bonus = this.deposit_mileage_max

        this.bonus_amount = bonus;
    }

    roll_change_check() {
        const value = this.form1.controls['is_bonus_rolling'].value;
        const k = this.bonus_type.find((v:any) => {
            if(value) {
                if(v.code == '3') return v; 
            } else {
                if(v.code == '1') return v;
            }
        })

        if(k) {
            this.deposit_mileage_rate = Number(k['bonus_rate']); // 입금보너스 RATE
            this.deposit_rolling_rate = Number(k['rolling_rate']); // 입금보너스 롤링 RATE
            this.deposit_rolling_text = k['text']

            // 보너스 RATE
            if(value) {
                this.form1.controls['bonus_code'].patchValue(3)
                this.bonus_rate = (this.is_first_deposit) ? this.deposit_mileage_rate + this.first_deposit_mileage_rate : this.deposit_mileage_rate ;
            } else {
                this.bonus_rate = 0;
                this.form1.controls['bonus_code'].patchValue(1)
            }

            // 보너스 금액 다시 계산
            this.fnSetBonus();
        }
    }

    GoPromotion($event:any) {
        if($event) $event.preventDefault();
        this.router.navigateByUrl('/promotion/read/19')
        this.onClose.emit('close')
    }
}
