import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { ApiService } from '../../../service/api.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


@Component({
    selector: 'app-bonus-deposit-modal',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent
    ],
    templateUrl: './bonus-deposit-modal.component.html',
    styles: ``
})
export class BonusDepositModalComponent {
    private subs: Subscription[] = [];

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    isLoading:boolean = false;

    KBC1:any;
    KBC2:any;
    KBC3:any;
    KBC4:any;
 
    constructor(
        private apiService: ApiService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get(`/promotion/code`).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) {
                    jsondata.data.map((v:any) => {
                        const code = v['promotion_code']
                        if(code == 'KBC1') this.KBC1 = v;
                        else if(code == 'KBC2') this.KBC2 = v;
                        else if(code == 'KBC3') this.KBC3 = v;
                        else if(code == 'KBC4') this.KBC4 = v;
                    })
                }
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }
    
    GoDetail() {
        this.router.navigateByUrl('/promotion/read/7')
        this.onClose.emit('close')
        
    }
}
