import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, HostListener, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '../../common/modal/modal.service';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { SwiperNoticeComponent } from '../home/swiper-notice.component';
import { ContestComponent } from '../home/contest.component';
import { toCamel, toSnake } from '@app/shared/lib/common';
import { GameListComponent } from './game-list.component';
import { ImgxDirective } from '../../common/imgx.directive';
import { AuthService, currentUserInfo } from '../auth/auth.service';


@Component({
    selector: 'app-slots',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SwiperDirective,
        SwiperNoticeComponent,
        ContestComponent,
        GameListComponent,
        ImgxDirective
    ],
    templateUrl: './slots.component.html',
    styles: ``,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SlotsComponent {
    private subs: Subscription[] = [];

    @Output() onSearchBtn: EventEmitter<string> = new EventEmitter<string>();

    swiperBaseOptions:SwiperOptions = {
        loop:false,
        slidesPerView: 3,
        spaceBetween: 10,
        autoplay: false,
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            815:{
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
            990:{
                slidesPerView: 5,
                slidesPerGroup: 5,
            },
            1100:{
                slidesPerView: 6,
                slidesPerGroup: 6,
            },
            1335:{
                slidesPerView: 7,
                slidesPerGroup: 7,
            }
        },
        injectStyles: [`
            :host {
                --swiper-theme-color: #fff;
                --swiper-navigation-size: 18px;
                --swiper-pagination-bottom: 0px;
            }
        `]
    };

    swiperItems:any = [
        { code:'slotLobby', name:'슬롯 로비', items:[], swiperOptions:<SwiperOptions>{} },
        { code:'freespin', name:'프리 스핀', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'megaways', name:'메가웨이즈', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'topGames', name:'인기게임', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'new', name:'신규게임', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'eventGame', name:'이벤트게임', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'recentGame', name:'최근이용게임', items:[],swiperOptions:<SwiperOptions>{} },
    ]


    tab:string = 'home'

    currentUser!:currentUserInfo | null;

    get modal() { return this.modalService }
    constructor(
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private router: Router,
        private apiService: ApiService,
        private authService: AuthService
    ){}

    ngOnInit(): void {
        this.swiperItems.map((it:any) => {
            it.swiperOptions = { 
                ...this.swiperBaseOptions, 
                navigation: {
                    nextEl: `.${it.code} .swiper-next`,
                    prevEl: `.${it.code} .swiper-prev`,
                }
            }
        })

        this.subs.push(
            this.authService.currentUser.subscribe(v => this.currentUser = v)
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngAfterViewInit() {
    }

    fnGetData() {
        this.apiService.post('/casino/slot_home', {}).subscribe((jsondata:any) => {
            if(jsondata.success && jsondata.data) {
                for (const [key, value] of Object.entries(jsondata.data)) {
                    const item = this.swiperItems.find((it:any) => it.code === key)
                    item.items = value
                }
            }
        })
    }


    onSearch() {
        this.onSearchBtn.emit('casino')
    }

    onTab(tab:string){
        this.tab = toSnake(tab);
        window.scrollTo(0, 0)
    }

    check_menu(code:string) {
        return (code == 'recentGame' && !this.currentUser) ? false : true;
    }
}
