<!-- 프로필 - vip 클럽 -->
<div class="modal-content">
    <div class="modal-body relative">
        <button class="absolute z-10 right-5 top-5 basic-hover" data-tw-dismiss="modal"><svg class="w-5 h-5 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        <div class="pt-8"></div>
        <div class="relative mb-4">
            <img class="w-[350px] mx-auto" src="/assets/custom_img/profile/ribbon.png" alt="">
            <span class="absolute left-0 top-2 w-full text-center text-base text-white font-extrabold">VIP 클럽</span>
        </div>
        <div class="bg-back pt-2 pb-8 px-4">
            <div class="p-4 bg-back2 text-center text-xs rounded">
                넉넉한 보상과 맞춤형 선물을 독점적으로 받으려면 레벨을 올리십시오! 
            </div>
            <div class="my-4 flex sm:flex-row flex-col w-full gap-4">
                <!-- vip_grade 클래스 : bronze silver gold plat dia  -->
                <div class="vip_grade rounded w-full sm:w-3/5">
                    <div class="flex gap-8 items-start px-4 rounded">
                        <div class="relative">
                            <!-- img 경로 : nostage.webp bronze_medal.png silver_medal.png gold_medal.webp platinum_medal.png diamond_medal.webp -->
                            <!-- <img class="w-28" [src]="'/assets/custom_img/profile/' + GetLevelImage(currentUser?.level)" alt="" *ngIf="currentUser?.level"> -->
                             <ng-template [ngIf]="currentUser?.level == 9">
                                <img class="w-28" src="/assets/custom_img/profile/bnw_medal.png" alt="">
                             </ng-template>
                             <ng-template [ngIf]="currentUser?.level != 9">
                                <img class="w-28" src="/assets/custom_img/profile/nostage.webp" alt="">
                             </ng-template>
                            <img class="absolute left-0 top-0 w-full h-full" src="/assets/custom_img/profile/light.svg" alt="">
                            <p class="z-10 absolute left-0 top-1/2 w-full -mt-1 text-center text-white"><b class="text-md">{{ level_name.toUpperCase() }}</b></p>
                        </div>
                        <div class="text-tit py-8 flex-1">
                            <div class="text-base font-semibold">VIP 진행 상황</div>
                            <div class="mt-2 vip-cont">
                                <div class="flex items-center gap-2">
                                    {{ current_exp | number }} XP 
                                    <!-- <div class="relative hover_box" *ngIf="exp_text">
                                        <button class="basic-hover pt-1 cursor-pointer"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg></button>
                                        <div class="z-20 absolute left-0 top-full w-[350px] -translate-x-1/3 bg-shadow text-xs p-3 rounded shadow">
                                            <p class="text-basic font-normal" [innerHTML]="exp_text | sanitize"></p>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- <div class="vip-process w-full h-2.5 mt-2 bg-[#f7f4f3] rounded">
                                    <div class="h-full rounded" [style.width]="bar + '%'"></div>
                                </div> -->
                            </div>
                            <p class="mt-2">{{ next_exp | number }} XP 까지 VIP {{ level_name.toUpperCase() }}</p>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:w-2/5 sm:pb-0 pb-4 vip-cont relative rounded bg-white dark:bg-[#2D3035] p-2">
                    <div class="flex flex-col h-full">
                        <div>
                            <div class="vip_flag host !absolute left-0 top-2 text-xs">VIP 전담 컨설턴트</div>
                            <div class="flex justify-end">
                                <div class="relative hover_box">
                                    <button class="basic-hover pt-1 cursor-pointer"><svg class="w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg></button>
                                    <div class="z-20 absolute right-0 top-full w-[350px] bg-shadow p-3 rounded shadow">
                                        <p class="text-basic font-normal">
                                            VIP 전담 컨설턴트는 귀하의 독점적인 고객 지원입니다. 언제든지 연락 주시기 바랍니다.
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-1 items-center flex-col justify-center">
                            <img class="w-36 mx-auto" src="/assets/custom_img/profile/host_kr.png" alt="">
                            <p class="text-basic mt-2"><b class="text-tit">플래티넘I</b> 에서 독점 VIP 전담 컨설턴트 잠금 해제</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white dark:bg-back2 p-4">
                <div class="text-center font-bold">KBC GAME의 독점 VIP 시스템으로 최고의 게임 경험을 발견하십시오!</div>
                                        
                <div class="overflow-x-auto overflow-y-hidden">
                    <ul class="nav nav-tabs gap-1 vip-tabs mt-4 min-w-[500px]" role="tablist">
                        <li class="nav-item " role="presentation">
                            <button class="nav-link w-full py-4 text-xs bronze" [ngClass]="{'active': tab == 'bronze'}" type="button" (click)="tab = 'bronze'">
                                <img src="/assets/custom_img/profile/bronze.png" alt=""> 브론즈 
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs silver" [ngClass]="{'active': tab == 'silver'}" type="button" (click)="tab = 'silver'">
                                <img src="/assets/custom_img/profile/silver.png" alt=""> 실버 
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs gold" [ngClass]="{'active': tab == 'gold'}" type="button" (click)="tab = 'gold'"> 
                                <img src="/assets/custom_img/profile/gold.png" alt=""> 골드 
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs plat" [ngClass]="{'active': tab == 'platinum-I'}" type="button" (click)="tab = 'platinum-I'"> 
                                <img src="/assets/custom_img/profile/platinum.png" alt=""> 플래티넘 I
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs plat" [ngClass]="{'active': tab == 'platinum-II'}" type="button" (click)="tab = 'platinum-II'"> 
                                <img src="/assets/custom_img/profile/platinum.png" alt=""> 플래티넘 II
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs dia" [ngClass]="{'active': tab == 'diamond-I'}" type="button" (click)="tab = 'diamond-I'"> 
                                <img src="/assets/custom_img/profile/diamond.png" alt=""> 다이아몬드 I
                            </button> 
                        </li>
                        <li class="nav-item " role="presentation"> 
                            <button class="nav-link w-full py-4 text-xs dia" [ngClass]="{'active': tab == 'diamond-II'}" type="button" (click)="tab = 'diamond-II'"> 
                                <img src="/assets/custom_img/profile/diamond.png" alt=""> 다이아몬드 II
                            </button> 
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-back vip-cont">
                    <!-- 브론즈 -->
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'bronze'}">
                        <div class="flex items-center gap-2 text-xs">
                            <div class="bronze vip_flag font-bold">브론즈</div>

                        </div>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">실시간 롤링</div>
                                <p class="text-xs text-basic">회원: 0.5%, 추천인: 0.4%</p>
                            </div>
                        </li>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">스포츠 롤링</div>
                                <p class="text-xs text-basic">회원: 0.5%, 추천인: 0.4%</p>
                            </div>
                        </li>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">카지노 롤링</div>
                                <p class="text-xs text-basic">회원: 0.4%, 추천인: 0.2%</p>
                            </div>
                        </li>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">슬롯게임 롤링</div>
                                <p class="text-xs text-basic">회원: 0.4%, 추천인: 0.3%</p>
                            </div>
                        </li>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">미니게임 롤링</div>
                                <p class="text-xs text-basic">회원: 0.4%, 추천인: 0.3%</p>
                            </div>
                        </li>
                        <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                            <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                <img width="30" height="30" [src]="img_bronze" alt="">
                            </i>
                            <div>
                                <div class="text-tit font-bold">최대 베팅금액</div>
                                <p class="text-xs text-basic"><span class="text-yellow font-bold">100%</span></p>
                            </div>
                        </li>
                    </div>
                    <!-- 실버 -->
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'silver'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="silver vip_flag font-bold">실버</div>
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.55%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.55%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.45%, 추천인: 0.2%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.45%, 추천인: 0.35%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.45%, 추천인: 0.35%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">120%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_silver" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 골드 -->
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'gold'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="gold vip_flag font-bold">골드</div>                            
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.6%, 추천인: 0.5%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.6%, 추천인: 0.5%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.5%, 추천인: 0.25%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.5%, 추천인: 0.4%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.5%, 추천인: 0.4%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">150%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_gold" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전용 이벤트</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 플래티넘 -->
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'platinum-I'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="plat vip_flag font-bold">플래티넘 I</div>
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.65%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.65%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.55%, 추천인: 0.3%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.55%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.55%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">200%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전용 이벤트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전담 컨설던트</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'platinum-II'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="plat vip_flag font-bold">플래티넘 II</div>
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.7%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.7%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.6%, 추천인: 0.3%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.6%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.6%, 추천인: 0.45%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">200%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전용 이벤트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_platinum2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전담 컨설던트</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 다이아 -->
                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'diamond-I'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="dia vip_flag font-bold">다이아몬드 I</div>
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.75%, 추천인: 0.6%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.75%, 추천인: 0.6%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.65%, 추천인: 0.35%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.65%, 추천인: 0.5%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.65%, 추천인: 0.5%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">200%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전용 이벤트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전담 컨설던트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond1" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">충환전 우선처리</div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-pane leading-relaxed p-5" [ngClass]="{'active': tab == 'diamond-II'}"> 
                        <div class="flex flex-wrap items-center gap-2 text-xs">
                            <div class="dia vip_flag font-bold">다이아몬드 II</div>
                            <span>* 이전 레벨의 혜택이 모두 포함되어 있습니다.</span>
                        </div>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4">
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">실시간 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.8%, 추천인: 0.65%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">스포츠 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.8%, 추천인: 0.65%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">카지노 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.7%, 추천인: 0.4%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">슬롯게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.7%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">미니게임 롤링</div>
                                    <p class="text-xs text-basic">회원: 0.7%, 추천인: 0.55%</p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">최대 베팅금액</div>
                                    <p class="text-xs text-basic"><span class="text-yellow font-bold">200%</span></p>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">레벨업 룰렛 티켓</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전용 이벤트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 items-center rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">전담 컨설던트</div>
                                </div>
                            </li>
                            <li class="flex items-center gap-3 border border-solid border-opacity-50 border-slate-300 p-3 rounded">
                                <i class="flex-shrink-0 rounded-full border border-solid border-slate-300 dark:border-slate-400 bg-opacity-100 dark:bg-opacity-30 bg-white dark:bg-slate-400 p-4">
                                    <img width="30" height="30" [src]="img_diamond2" alt="">
                                </i>
                                <div>
                                    <div class="text-tit font-bold">충환전 우선처리</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <a (click)="Go('/vip', $event)" class="btn mt-6 border-slate-300 w-full h-12 text-primary"> KBC GAME’s VIP 시스템에 대하여 더 알아보세요.</a>
        </div>
    </div>
</div>
 