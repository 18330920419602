<ng-template [ngIf]="isLoading">
    <app-loading />
</ng-template>

<div class="mt-2 md:mt-4 md:p-3 py-3 bg-tab p-2">
    <div class="py-5 border-b border-slate-300 border-opacity-60">
        <h3 class="text-tit text-xl font-extrabold">{{ item['subject']}}</h3>
        <p class="mt-4">📅 {{ item['createdAt'] | moment:'YYYY-MM-DD HH:mm:ss'}}</p>
    </div>
    <div class="py-4 border-b border-slate-300 border-opacity-60">
        {{ item['content']}}
    </div>
    <button (click)="list()" class="btn-green mt-2 px-3 py-2 font-extrabold">목록</button>
</div>
