import { Directive, ElementRef, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import * as QRCode  from 'qrcode'

@Directive({
    selector: '[qrcode]',
    standalone: true,
  })
export class QrCodeDirective {
    @Input() alt!:string

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['alt']) {
            QRCode.toDataURL(this.alt).then(url => {
                this.elementRef.nativeElement.setAttribute('src', url)
            })
        }
    }
}