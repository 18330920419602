<div class="modal-content h-full">
    <div class="modal-head" style="position: sticky;">
        <div class="flex items-center justify-between p-2">
            <button class="basic-hover" data-tw-dismiss="modal">
                <svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
            </button>
            <button class="basic-hover" data-tw-dismiss="modal">
                <svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg>
            </button>
        </div>
    </div>
    <div class="modal-body h-full">
        <div class="game-box game m-body">
            <iframe #iframeEl [src]="options.url | sanitize:'url'" class="iframe-full" (load)="iframeLoad($event)"></iframe>
        </div>
    </div>
</div>
