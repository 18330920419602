import { Directive, ElementRef, HostListener, Output, EventEmitter, Attribute, SimpleChanges, Input } from '@angular/core';
import { environment } from '../environments/environment';

@Directive({
    selector: '[imgx]',
    standalone: true,
  })
export class ImgxDirective {
    @Input() src!:string;
    
    private readonly assetsDomain = environment.assetsUrl
    constructor(
        private el: ElementRef,
    ) {
        const supports = 'loading' in HTMLImageElement.prototype;
        if(supports) {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['src']) {
            const prev = changes['src'].previousValue;
            const current = changes['src'].currentValue
            if(current) {
                if(!current.startsWith('http') && !current.startsWith('/assets/')) {
                    this.el.nativeElement.setAttribute('src', `${this.assetsDomain}/` + current)
                } else {
                    this.el.nativeElement.setAttribute('src', current)
                }
            } else {
                this.el.nativeElement.setAttribute('src', current)
            }
        }
    }
}