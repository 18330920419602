import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { catchError, map, Observable, of } from "rxjs";
import { ApiService } from "../../service/api.service";
import { inject } from "@angular/core";
import { SiteService } from "../../service/site.service";

export const SiteGuard: CanActivateFn = (
    route:ActivatedRouteSnapshot, 
    state:RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const apiService = inject(ApiService)
    const router = inject(Router)
    const siteService = inject(SiteService)
    
    return apiService.get('/site').pipe(
        map((jsondata:any) => {
            if(jsondata.success) {
                if(jsondata.data.maintain) router.navigateByUrl('/upcoming');
                else {
                    const telegram = jsondata.data.telegram ? jsondata.data.telegram : ''
                    siteService.setValue({ telegram: telegram })
                }
                
                return true;
            }
            else {
                router.navigateByUrl('/upcoming');
                return false;
            }
        }), 
        catchError((err:any) => {
            router.navigateByUrl('/upcoming');
            return of(false);
        })
    )
}