<div class="modal-content">
    <div class="modal-head">
        <div class="flex items-center justify-between p-4">
            <p class="flex items-center text-tit font-extrabold text-base">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-5 h-5" fill="#fff"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                <span class="ml-1">{{ title }}</span>
            </p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
    </div>
    <div class="modal-body rounded overflow-hidden dark:bg-back2 bg-white">
        <div clsss="flex tems-center justify-center">
            {{ content }}
        </div>
        <div class="p-5">
            <div class="text-center mt-10">
                <button class="btn-green w-2/3 h-12 font-bold" data-tw-dismiss="modal">확인</button>
            </div>
        </div>
    </div>
</div>