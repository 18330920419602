import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { EventEmitter, inject } from '@angular/core';
import { Observable, catchError, firstValueFrom, lastValueFrom, map, of } from 'rxjs';
import { ModalService } from '../../common/modal/modal.service';

export const AuthGuard: CanActivateFn = (
        route:ActivatedRouteSnapshot, 
        state:RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
        
    const router = inject(Router)
    const authService = inject(AuthService)
    const modalService = inject(ModalService)

    const onModalEvent: EventEmitter<any> = new EventEmitter<any>()
    
    return new Promise((resolve) => {
        const currentUser = authService.currentUser.getValue()

        if(currentUser) {
            resolve(true)
            return;
        } 

        // 로그인 화면
        sessionStorage.setItem('returnUrl', state.url);
        modalService.open(null, 'sign-in', null, onModalEvent);
        onModalEvent.subscribe(v => {
            const { component, event } = v;
            if(event == 'close') {
                const currentUser = authService.currentUser.getValue()
                if(currentUser) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }
        })
    })
};
