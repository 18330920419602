<!-- 입금 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <ng-template [ngIf]="isLoading">
        <app-loading />
    </ng-template>
    <!-- 기본 -->
    <div class="modal-body relative" >
        <div class="relative px-5 bg-modaldark rounded-t">
            <div class="flex items-center justify-between py-3">
                <p class="text-tit font-extrabold text-base">입금</p>
                <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
        <ng-container *ngIf="currentUser">
            <ng-template [ngIf]="!is_crypto_user" >
                <app-deposit-currency [options]="options" (onClose)="onClose.emit($event)" />
            </ng-template>
            <ng-template [ngIf]="is_crypto_user" >
                <app-deposit-crypto [options]="options" (onClose)="onClose.emit($event)" />
            </ng-template>
        </ng-container>
    </div>
</div>