<div class="container_wrap" >
    <div class="casino_visual bg-back2 py-8">
        <div class="container">
            <app-swiper-notice />
        </div>
    </div>

    <div class="container casino_main">
        <div class="casino_search flex-1 mx-2 mt-5 relative">
            <svg class="absolute left-3 top-3 w-4 h-4 fill-place"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg>
            <input type="text" name="casino_search" class="form-control form-control-rounded bg-back2 pl-9" placeholder="게임이름 | 게임사 | 카테고리 태그" (click)="onSearch()">
            <svg class="absolute right-3 top-4 w-3 h-3 fill-title close_btn cursor-pointer"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg>
        </div>
    </div>

    <div class="py-10">
        <div class="container">
            <ul class="nav nav-boxed-tabs gap-2 overflow-x-auto scrollbar_x">
                <li class="nav-item" role="presentation"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'home'}" type="button" (click)="onTab('home')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Lobby"></use></svg> 
                    </button>
                </li>
                <li class="nav-item" > 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'slot_lobby'}" type="button" (click)="onTab('slot_lobby')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Slots"></use></svg> 슬롯 로비
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'freespin'}" type="button" (click)="onTab('freespin')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_PicksForYou"></use></svg> 프리 스핀
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'megaways'}" type="button" (click)="onTab('megaways')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_LiveCasino"></use></svg> 메가웨이즈
                    </button>
                </li>
                <li class="nav-item" > 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'top_games'}" type="button" (click)="onTab('top_games')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_HotGame"></use></svg> 인기게임
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'new'}" type="button" (click)="onTab('new')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewReleases"></use></svg> 신규게임
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'event_game'}" type="button" (click)="onTab('event_game')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_FeatureBuy-in"></use></svg> 이벤트게임
                    </button>
                </li>
                <ng-template [ngIf]="currentUser">
                    <li class="nav-item"> 
                        <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'recent_game'}" type="button" (click)="onTab('recent_game')">
                            <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Recent"></use></svg> 최근이용게임
                        </button>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>
    
    <div class="tab-content container pb-10">
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'home'}"> 
            <ng-container *ngFor="let it of swiperItems; let i = index;">
                <div class="mb-11" [ngClass]="it.code" *ngIf="check_menu(it.code)">
                    <div class="flex items-center justify-between">
                        <p class="text-xl text-tit font-medium">{{ it.name }}</p>
                        <div>
                            <button type="button" (click)="onTab(it.code)" class="text-primary mr-2">모두보기</button>
                            <button type="button" class="btn btn-top type02 h-9 swiper-prev"><svg class="w-3 h-3 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                            <button type="button" class="btn btn-top type02 h-9 swiper-next"><svg class="w-3 h-3 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                        </div>
                    </div>
                    <div class="slide_box mt-2.5 casino_slide overflow-hidden" *ngIf="it.items.length > 0">
                        <swiper-container swiper init="false" [swiperOptions]="it.swiperOptions">
                            <swiper-slide class="cursor-pointer bg-list rounded" *ngFor="let item of it.items; let i = index;">
                                <a [routerLink]="it.code == 'slotLobby' ? '/slots/provider/' + item['idx'] : '/slots/play/' + item['idx']">
                                    <img [src]="item['image']" [alt]="item['image']" *ngIf="item['image']" imgx>
                                </a>
                                <div class="flex items-center justify-between px-2 py-3">
                                    <p class="font-medium truncate game_tit"><a [routerLink]="it.code == 'slot_lobby' ? '/slots/provider/' + item['idx'] : '/slots/play/' + item['idx']">{{ item['name'] }}</a></p>
                                    <div class="casino_gameinfo" *ngIf="item['notice']">
                                        <svg class="w-5 h-5 fill-basic cursor-pointer"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                        <div class="hover_box p-2 bg-back2">
                                            <div class="flex items-center justify-between bg-modaldark p-1">
                                                <span>{{item['notice']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
            </ng-container>
            

            <!-- 최신 베팅&레이스 -->
            <div>
                <app-contest />
            </div>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'slot_lobby'}"> 
            <ng-template [ngIf]="tab == 'slot_lobby'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'freespin'}"> 
            <ng-template [ngIf]="tab == 'freespin'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'megaways'}"> 
            <ng-template [ngIf]="tab == 'megaways'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'top_games'}"> 
            <ng-template [ngIf]="tab == 'top_games'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'new'}"> 
            <ng-template [ngIf]="tab == 'new'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'event_game'}"> 
            <ng-template [ngIf]="tab == 'event_game'">
                <app-game-list [tag]="tab" />
            </ng-template>
        </div>
        <ng-template [ngIf]="currentUser">
            <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'recent_game'}"> 
                <ng-template [ngIf]="tab == 'recent_game'">
                    <app-game-list [tag]="tab" />
                </ng-template>
            </div>
        </ng-template>
    </div>
</div>
