<ng-template [ngIf]="isLoading">
    <app-loading />
</ng-template>
<!-- 출금 모달 -->
<div class="overflow-y-auto scrollbar h-auto sm:h-[680px] text-sub">
    <div class="leading-relaxed p-6 sm:px-10" >
        <form role="form" [formGroup]="form1">
            <div class="modal-tab-wrap">
                <!-- 입금 -->
                <div class="deposit-detail">
                    <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress">
                        <div class="flex-1">출금신청이 진행중 입니다.</div>
                        <!-- <button type="button" class="btn btn-purple2 p-1" (click)="withdraw_cancel()" *ngIf="in_progress.status == 'REQUEST'">취소하기</button> -->
                    </div>
                    <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="!crypto_addrsss_items || crypto_addrsss_items.length == 0">
                        참고: <span class="text-tit">고객센터를 통해 출금주소를 등록하세요.</span>
                    </div>
                    <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                        주의사항: <span class="text-tit">KBC GAME에서 다이렉트로 업비트나 빗썸 등 한국 거래소로 전송 하지마세요. <br />KBC GAME > 개인지갑이나 해외거래소 > 한국거래소 순서로 이용하시길 권장합니다.</span>
                    </div>
                    <div class="md:flex items-center justify-between gap-1" *ngIf="crypto_addrsss_items">
                        <div class="flex-1">
                            <div class="mt-3">출금주소</div>
                            <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                                 <span *ngIf="selected_item">
                                    <input type="text" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" [placeholder]="selected_item.address" required autocomplete="off" >
                                </span>
                                 <span *ngIf="!selected_item">
                                    <input type="text" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="주소록에서를 선택하세요." required autocomplete="off" >
                                 </span>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="mt-3">출금 주소록</div>
                            <div class="ng-select flex-1 mt-1" ng-select>
                                <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark">
                                    <span *ngIf="selected_item">{{ selected_item.alias }} - [{{ selected_item.exchange }}]</span>
                                    <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                                </button>
                                <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                                    <li class="py-2" *ngFor="let item of crypto_addrsss_items" (click)="change_address($event, item)">{{ item.alias }} - [{{ item.exchange }}]</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" formControlName="crypto_address_idx"  [ngClass]="{ 'is-invalid': submitted && f['crypto_address_idx'].errors }">
                    <div *ngIf="submitted && f['crypto_address_idx'].errors" class="invalid-feedback">
                        <div *ngIf="f['crypto_address_idx'].errors">{{ f['crypto_address_idx'].errors['check_error']}}</div>
                    </div>
                    <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                        <div class="flex items-center gap-4 text-base">
                            <p class="pl-4 text-tit">₩</p>
                            <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" />
                        </div>
                    </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                        <div *ngIf="f['amount'].errors">{{ f['amount'].errors['check_error']}}</div>
                    </div>
                    <div class="cash_btn_box mt-2">
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                        <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0);">초기화</button>
                    </div>
                    <div class="border border-slate-300 bg-tab rounded-lg px-5 py-1 mt-3 text-xs">
                        <div class="flex items-center justify-between pt-1">
                            <div>
                                <b class="text-tit">롤링 등록 시간</b>
                            </div>
                            <div *ngIf="rolling_item">{{ rolling_item['rolling_startedAt'] | moment:'YYYY-MM-DD HH:mm:ss' }}</div>
                        </div>
                        <div class="flex items-center justify-between pt-1">
                            <div>
                                <b class="text-tit">롤링 등록 이후 총 충전액 (보너스 금액)</b>
                            </div>
                            <div *ngIf="rolling_item">{{ rolling_item['rolling_deposit'] | number:'1.0-0'}}원 ({{rolling_item['rolling_bonus_amount'] | number:'1.0-0'}} P)</div>
                        </div>
                        <div class="flex items-center justify-between pt-1">
                            <div>
                                <b class="text-tit">출금가능 롤링 금액</b>
                            </div>
                            <div *ngIf="rolling_item">{{ rolling_item['rolling_times'] | number:'1.0-0'}} </div>
                        </div>
                        <div class="grid grid-cols-4 gap-1 pt-1">
                            <div>
                                <b class="text-yellow">스포츠조합</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ rolling_item['rolling_sports'] | number:'1.0-0'}}</span>
                            </div>
                            <div>
                                <b class="text-yellow">스포츠단폴</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ rolling_item['rolling_sports_one'] | number:'1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="grid grid-cols-4 gap-1 pt-1">
                            <div>
                                <b class="text-yellow">카지노</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ rolling_item['rolling_casino'] | number:'1.0-0'}}</span>
                            </div>
                            <div>
                                <b class="text-yellow">슬롯</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ rolling_item['rolling_slot'] | number:'1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="grid grid-cols-4 gap-1 pt-1">
                            <div>
                                <b class="text-yellow">해외스포츠</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ rolling_item['rolling_betradar'] | number:'1.0-0'}}</span>
                            </div>
                            <div>
                                <b class="text-yellow">미니게임</b>
                            </div>
                            <div class="text-right">
                                <span *ngIf="rolling_item">{{ GetMinigameRolling() | number:'1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="flex items-center justify-between pt-2">
                            <div>
                                <b class="text-tit">현재까지 롤링 요율</b>
                            </div>
                            <div *ngIf="rolling_item">{{ current_rolling() | number:'1.0-2' }}%</div>
                        </div>
                    </div>

                    <div class="text-center mt-10">
                        <button type="button" class="btn-green w-1/2 h-12 font-bold" (click)="onSubmit()" [disabled]="!crypto_addrsss_items || crypto_addrsss_items.length == 0 || in_progress">출금 신청</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
