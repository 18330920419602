import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TurnstileComponent } from '@app/shared/lib/turnstile/turnstile.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../../service/api.service';
import { ValidatorService } from '../../../service/validator.service';
import { passwordView } from '../../../common/common';
import { ModalService } from '../../../common/modal/modal.service';
import moment from 'moment-timezone';
import { CountdownDirective } from '@app/shared/lib/directive/countdown.directive';
import { LoadingComponent } from '../../../common/loading/loading.component';

@Component({
    selector: 'app-sign-up-modal',
    standalone: true,
    imports: [
        CommonModule,
        TurnstileComponent,
        ReactiveFormsModule,
        CountdownDirective,
        LoadingComponent
    ],
    templateUrl: './sign-up-modal.component.html',
    styles: ``
})
export class SignUpModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    siginUpForm!: FormGroup;
    sigining = false;
    onDuplicating_uid = false; // 아이디 중복확인
    onDuplicating_nick = false; // 닉네임 중복확인
    requesting = false;
    requested = false; // 인증번호 전송
    verifying = false;
    verifyed = false; // 인증완료
    submitted = false;
    returnUrl: string;
    hostname: string = '';
    error = '';
    auth_errors = {
        hp: ``,
        code: ``,
    }
    ok = {
        uid: ``,
        nick: ``,
    }
    smsExpiredAt:string = '';
    referral_code:string=''

    siteKey = environment.CLOUDFLARE.TURNSTILE.SITE_KEY;

    recaptchaReady = false;

    showPhoneTab = false;
    showBankTab = false;
    initBankCode = false;
    COUNTRY_CODE:any[] = environment.COUNTRY_CODE
    BANK_CODE:any[] = environment.BANK_CODE
    INIT_BANK_CODE = environment.BANK_CODE

    uid_error:string | null = null;
    nick_error:string | null = null;
    hp_error:string | null = null
    auth_code_error:string| null = null

    tab:string='tab1'

    telegram_account:string=''
    isLoading:boolean = false;

    get f() { return this.siginUpForm.controls; }
    get passwordView() { return passwordView }

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private apiService: ApiService,
        private validatorService: ValidatorService,
        private modalService: ModalService
    ) {
        this.siginUpForm = this.formBuilder.group({
            uid: ['', Validators.required],
            nick: ['', Validators.required],
            pwd: ['', Validators.required],
            pwd_confirm: ['', Validators.required, this.validatorService.compare('pwd')],
            country: ['+82', Validators.required],
            hp: ['', Validators.required],
            auth_code: ['', Validators.required],
            auth_complete: ['', Validators.required],
            bank_name: [{ value: '', disabled: false }, Validators.required],
            bank_account: ['', Validators.required],
            bank_owner: ['', Validators.required],
            referral_code: [''],
            agree_policy: ['', Validators.required],
            agree_promotion: [''],
            'cf-turnstile-response': ['']
        });

        this.returnUrl = sessionStorage.getItem('returnUrl') || '/';
        this.hostname = window.location.hostname.replace('www.', '')

        this.clearStorage();
    }

    ngOnInit(): void {
        const referral_code = sessionStorage.getItem('referral')
        if(referral_code) this.f['referral_code'].patchValue(referral_code)

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get('/site/info-signup').subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                this.telegram_account = jsondata.data.telegram_account;
                const items = jsondata.data.bank.split(",")
                this.BANK_CODE = []
                this.INIT_BANK_CODE = []
                items.map((item:any) => {
                    this.BANK_CODE.push({ name: item, code: item })
                    this.INIT_BANK_CODE.push({ name: item, code: item })
                })
                this.initBankCode = true
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    sendCaptchaResponse(captchaResponse: any) {
        this.recaptchaReady = true;
        this.f['cf-turnstile-response'].patchValue(captchaResponse)
    }

    clearStorage() {
        const signout = sessionStorage.getItem('signout');
        if(signout) {
            const d = JSON.parse(signout);
            this.error = d.message;

            sessionStorage.removeItem('signout');
        }
        
        this.authService.clear();
    }

    onSignUp() {
        this.submitted = true;
        if(this.siginUpForm.invalid) {
            if(this.uid_error || this.f['uid'].errors || this.nick_error || this.f['nick'].errors || this.f['pwd'].errors || this.f['pwd_confirm'].errors) {
                this.tab = 'tab1'
            } else if( this.hp_error || this.auth_code_error || this.f['auth_code'].errors || this.f['auth_complete'].errors) {
                this.tab = 'tab1'
            } else {
                this.tab = 'tab2'
            }
            return;
        }

        this.sigining = true;
        this.apiService.post('/auth/signup', this.siginUpForm.value).subscribe({
            next: (v) => {
                if(v.success) {
                    this.sigining = false;
                    this.onClose.emit('success')
                    // this.router.navigateByUrl(this.returnUrl);
                    this.modalService.open(null, 'message', { title: `회원가입`, message: v.message })
                } else {
                    this.raiseError(v.message);
                    this.modalService.open(null, 'message', { title: `회원가입 실패`, message: v.message })
                }
            },
            error: (err) => {
                this.raiseError(err.message || err);
                this.modalService.open(null, 'message', { title: `회원가입 실패`, message: err.message || err })
            },
        })
    }

    GoModal(next:string, $event:any = null): void {
        if($event) $event.preventDefault()

        this.onClose.emit()
        this.modalService.open(null, next)
    }

    raiseError(error:any) {
        this.error = error;
        this.sigining = false;
    }

    onSearch($event:KeyboardEvent) {
        const keyword = ($event.target as HTMLInputElement).value
        if(!keyword) this.COUNTRY_CODE = environment.COUNTRY_CODE
        else {
            this.COUNTRY_CODE = environment.COUNTRY_CODE.filter(v => v.name.toLowerCase().includes(keyword.toLowerCase()))
        }
    }

    onCountry($event:any) {
        this.f['country'].patchValue($event['code'])
        this.showPhoneTab = false
    }

    onBankSearch($event:KeyboardEvent) {
        const keyword = ($event.target as HTMLInputElement).value
        if(!keyword) this.BANK_CODE = this.INIT_BANK_CODE
        else {
            this.BANK_CODE = this.INIT_BANK_CODE.filter(v => v.name.toLowerCase().includes(keyword.toLowerCase()))
        }
    }

    onBank($event:any) {
        this.f['bank_name'].patchValue($event['code'])
        this.showBankTab = false
    }

    onDuplicate(kind:string) {
        let value:string=''
        if(kind =='uid') {
            const uid = this.f['uid'].value.replace(/\s+/g, '')
            if(!uid) {
                this.uid_error = `아이디를 번호를 입력하세요.`
                return;
            }

            value = uid;
            this.onDuplicating_uid = true;
        } else if(kind == 'nick') {
            const nick = this.f['nick'].value.replace(/\s+/g, '')
            if(!nick) {
                this.nick_error = `닉네임을 입력하세요.`
                return;
            }

            value = nick;
            this.onDuplicating_nick = true;
        }
        
        this.apiService.post('/auth/duplicate', { kind: kind, value: value }).subscribe({
            next: (jsondata) => {
                if(kind =='uid') this.onDuplicating_uid = false
                else if(kind == 'nick') this.onDuplicating_nick = false;

                if(jsondata) {
                    if(!jsondata.success) {
                        if(kind =='uid') this.uid_error = jsondata.message
                        else if(kind == 'nick') this.nick_error = jsondata.message
                    } else {
                        if(kind =='uid') {
                            this.uid_error = null;
                            if(jsondata.message) this.ok.uid = jsondata.message
                        } else if(kind =='nick') {
                            this.nick_error = null;
                            if(jsondata.message) this.ok.nick = jsondata.message
                        }
                        // this.duplicated = true;
                        // (window as any).alert(jsondata.message)
                    }
                }
            }, 
            error: (e) => {
                if(kind =='uid') this.onDuplicating_uid = false
                else if(kind == 'nick') this.onDuplicating_nick = false;
            }
        })
    }

    onAuthCode() {
        const hp = this.f['hp'].value.replace(/[^0-9]/g, '')
        if(!hp) {
            this.hp_error = `휴대폰 번호를 입력하세요.`
            return;
        }

        // const cellPhone = /^(?:(010)|(01[1|6|7|8|9]))-\d{3,4}-(\d{4})$/;
        const cellPhone = /^(?:(010)|(01[1|6|7|8|9]))\d{7,8}$/;
        if(!cellPhone.test(hp)) {
            this.hp_error = `정상적인 휴대폰 번호를 입력하세요.`
            return;
        }

        this.requesting = true;
        this.apiService.post('/auth/verify-code', { hp: hp }).subscribe({
            next: (jsondata) => {
                if(jsondata) {
                    if(!jsondata.success) this.hp_error = jsondata.message
                    else {
                        this.hp_error = null;
                        this.requested = true;

                        this.smsExpiredAt = moment.tz(jsondata.data.expiredAt, 'Asia/Seoul').format()
                    }
                }
                this.requesting = false
            }, 
            error: (e) => {
                this.requesting = false
            }
        })
    }

    onAuthCodeConfirm() {
        const hp = this.f['hp'].value.replace(/[^0-9]/g, '')
        if(!hp) {
            this.hp_error = `휴대폰 번호를 입력하세요.`
            return;
        }

        const auth_code = this.f['auth_code'].value.replace(/[^0-9]/g, '')
        if(!auth_code) {
            this.auth_code_error = `인증번호를 입력하세요.`
            return;
        }

        this.verifying = true;
        this.apiService.post('/auth/verify-check', { hp: hp, auth_code: auth_code }).subscribe({
            next: (jsondata) => {
                if(jsondata) {
                    if(!jsondata.success) this.auth_code_error = jsondata.message
                    else {
                        this.auth_code_error = null;
                        this.verifyed = true;
                        this.f['auth_complete'].patchValue('1')
                    }
                }
                this.verifying = false;
            }, 
            error: (e) => {
                this.verifying = false;
            }
        })
    }
}
 