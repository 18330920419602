import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PopupService } from './popup.service';
import { fadeIn, fadeOut } from '@app/shared/lib/animation';
import { ImgxDirective } from '../imgx.directive';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment-timezone';

@Component({
    selector: 'app-popup',
    standalone: true,
    imports: [
        CommonModule,
        ImgxDirective,
        FormsModule
    ],
    templateUrl: './popup.component.html',
    styles: `
.popup-container {
    box-sizing: border-box;
    margin-top: 50px;
    display: inline-block;
    width: 30%;
    max-width: 380px;
    z-index: 12;
    background-color: #191a21;
    color: #fff;
    font-size: 12px;
    padding-top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.popup-container .close-button {
    height: 30px;
    line-height: 30px;
    box-sizing: border-box
}

.popup-container .close-button-wrap button {
    height: 30px;
    line-height: 33px;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-weight: normal;
    color: #ffce43;
    float: right;
    cursor: pointer
}

.popup-container p {
    font-size: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    box-sizing: border-box
}

.popup-container p img {
    width: 100% !important
}

@media only screen and (max-width:980px) {
    .popup-container {
        box-sizing: border-box;
        margin-top: 50px;
        display: inline-block;
        width: 80%;
        z-index: 12;
        background-color: #313238;
        color: #fff;
        font-size: 12px;
        padding-top: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%)
    }
}    
    `,
    animations:[
        fadeIn,
        fadeOut,
    ]
})
export class PopupComponent {
    popup = {
        items: <any>[],
    }
    constructor(
        private readonly popupService: PopupService,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        this.popupService.getData().subscribe(v => {
            this.popup.items = []
            
            // 24 시간동안 보지 않기
            const cookies = this.cookieService.get('disable_popup')
            const disable_cookie = (cookies) ? JSON.parse(cookies) : []

            // 세션 time동안 보지 않기
            const session = sessionStorage.getItem('disable_popup');
            const disable_session = (session) ? JSON.parse(session) : []
            v.map((v:any) => {
                const k = disable_session.find((idx:any) => idx == v.idx) // 먼저 세션을 찾고
                if(!k) {
                    const k2 = disable_cookie.find((idx:any) => idx == v.idx) // 쿠키를 비교
                    if(!k2) this.popup.items.push(v);
                }
            })
        })
    }

    close(idx:string | undefined) {
        const index = this.popup.items.findIndex((v:any) => v.idx == idx)
        if(index !== -1) {
            const item = this.popup.items[index];
            const cookies = this.cookieService.get('disable_popup')
            const disable_cookie = (cookies) ? JSON.parse(cookies) : []
            if(item['hide_day']) { // 24시간 처리
                const k = disable_cookie.findIndex((v:string) => v == idx)
                if(k !== -1) disable_cookie.splice(k)

                disable_cookie.push(idx)
                const d = moment.tz('Asia/Seoul').add('1','day');
                this.cookieService.delete('disable_popup', '/')
                this.cookieService.set('disable_popup', JSON.stringify(disable_cookie), d.toDate(), '/')
            }
            this.popup.items.splice(index, 1);
        }

        const session = sessionStorage.getItem(`disable_popup`)
        const disable_session = (session) ? JSON.parse(session) : []
        disable_session.push(idx)

        sessionStorage.setItem('disable_popup', JSON.stringify(disable_session));

    }
}
