import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { LoadingComponent } from '../../common/loading/loading.component';
import {Clipboard} from "@angular/cdk/clipboard"
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';
import { RemainDirective } from '@app/shared/lib/directive/remain.directive';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import { QrCodeDirective } from '@app/shared/lib/directive/qr-code.directive';

@Component({
    selector: 'app-deposit-crypto',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingComponent,
        NgSelectDirective,
        RemainDirective,
        FilterPipe,
        QrCodeDirective
    ],
    templateUrl: './deposit-crypto.component.html',
    styles: ``
})
export class DepositCryptoComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    currentUser!:currentUserInfo | null

    form1!: FormGroup;
    submitted = false;

    keyword:string=''
    selectedCrypto = 'USDT'
    selectedNetwork = 'ERC-20'
    selectedNetworks:any = ['ERC-20', 'TRC-20', 'BEP-20']
    
    crypto_codes:any = []
    crypto_networks:any = [
        { code: 'ERC-20', value: 'ERC-20' },
        { code: 'TRC-20', value: 'TRC-20' },
        { code: 'BEP-20', value: 'BEP-20' },
    ]
    crypto_items:any; // 크립토 리스트
    
    in_progress_item:any // 입금 대기중
    expected_amount!:number; // 예상 조회 금액
    expected_rate!:number;  // 예상 조회금액 적용환율

    is_promotion:boolean = false; // 프로모션 입금

    isLoading:boolean = false;
    copyMessage:string=''

    bonus_type:any;
    deposit_mileage_max:number = 0; // 입금보너스 맥스
    deposit_mileage_rate:number = 1; // 입금보너스 rate
    deposit_rolling_rate:number = 100;  // 보너스 롤링 rate
    deposit_rolling_text:string=''
    min_deposit_usdt:number = 50;   // 최소 입금액

    bonus_rate:number = 0;

    @ViewChild('buttonCrypto') buttonCrypto!: ElementRef;
    get f() { return this.form1.controls; }
    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private clipboard: Clipboard,
    ) {
        this.form1 = this.formBuilder.group({
            code:['USDT', Validators.required],
            amount:['', Validators.required],
            network:['ERC-20', Validators.required],
            dcode: ['USDT', Validators.required],
            promotion_code: [''],
            is_bonus_rolling: [false, Validators.required ],
            bonus_code:['1', Validators.required],
        });
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['options']) {
            const prev = changes['options'].previousValue;
            const current = changes['options'].currentValue
            if(current) {
                if(current['promotion_code']) {
                    this.is_promotion = true;
                    this.form1.controls['promotion_code'].patchValue(current['promotion_code'])
                    this.form1.controls['is_bonus_rolling'].patchValue(true)
                    this.form1.controls['is_bonus_rolling'].disable() // 프로모션 입금이면 롤링을 무조건
                }
            }
        }
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.post("/deposit/info-deposit",  { promotion_code: this.form1.controls['promotion_code'].value}).subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    if(jsondata.data['bonus_type']) {
                        this.bonus_type = jsondata.data['bonus_type']

                        this.bonus_type.map((v:any) => {
                            if(v.code == '3') {
                                this.deposit_mileage_rate = Number(v['bonus_rate']); // 입금보너스 RATE
                                this.deposit_rolling_rate = Number(v['rolling_rate']); // 입금보너스 롤링 RATE
                                this.deposit_rolling_text = v['text'];
                                this.deposit_mileage_max = Number(v['deposit_mileage_max'])
                                this.min_deposit_usdt = Number(v['min_deposit_usdt'])
                            }
                        })
                    }


                    jsondata.data.crypto_items.map((item:any) => {
                        const k = this.crypto_codes.find((v:any) => v.code == item.code)
                        if(!k) this.crypto_codes.push({ code: item.code, value: item.name })
                    })
                    this.crypto_items = jsondata.data.crypto_items;
                    this.in_progress_item = jsondata.data.in_progress;
                } else {
                    this.onClose.emit('faild')
                    if(jsondata.message) (window as any).alert(jsondata.message)
                    setTimeout(() => {
                        this.onClose.emit('faild')
                    }, 1000);
    
                }
            }, error: (err) => {
                this.isLoading = false
            }
        });
    }

    onSubmit() {
        // this.submitted = true;
        // if(this.form1.invalid) return;

        this.isLoading = true;
        this.apiService.post('/deposit/fulgurpay-process', this.form1.value).subscribe({
            next: (jsondata) =>{
                this.isLoading = false;
                if(jsondata.success) this.in_progress_item = jsondata.data.in_progress;

                if(jsondata.message) (window as any).alert(jsondata.message)
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    deposit_cancel() {
        this.isLoading = true;
        this.apiService.post('/deposit/fulgurpay-cancel', { idx: this.in_progress_item.idx }).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) this.fnGetData();
                if(jsondata.message) (window as any).alert(jsondata.message)
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    check_currency_rate() {
        this.submitted = true;
        const amount = this.f['amount'].value;
        if(!amount || amount == '0') {
            this.f['amount'].setErrors({ 'check_error': `금액을 입력하고 예상금액을 조회하세요.` });
            return;
        }

        if(this.form1.invalid) return;

        this.isLoading = true;
        this.apiService.post('/deposit/currency-rate', this.form1.value).subscribe({
            next: (jsondata: any) => {
                this.isLoading = false;
                if(jsondata.message) (window as any).alert(jsondata.message)
                if(jsondata.success) {
                    // 예상 금액
                    this.expected_amount = Number(jsondata.data.rate) * Number(amount)
                    // 환율 
                    this.expected_rate = jsondata.data.rate;
                }
            },
            error: (err: any) => {
                this.isLoading = false;
            }
        });
        
    }

    selected_crypto_code(value:string) {
        this.crypto_networks.splice(0)
        if(value) {
            this.crypto_items.map((v:any) => {
                if(v.code == value) this.crypto_networks.push({ code: v.network, value: v.network })
            })
        }
        this.f['code'].patchValue(value)
    }
    
    change_code($event:any, item:any) {
        const el = $event.target
        const parent = el.closest('.ng-select2'); // style.scss
        if(parent) parent.classList.remove('on')
        
        const value = item['code']
        this.crypto_networks.splice(0)
        if(value) {
            this.crypto_items.map((v:any) => {
                if(v.code == value) this.crypto_networks.push({ code: v.network, value: v.network })
            })
        }
    }

    change_network($event:any, item:any) {
        const el = $event.target
        const parent = el.closest('.ng-select'); // style.scss
        if(parent) parent.classList.remove('on')

        const value = item['code']
        if(value) {
            const item = this.crypto_items.find((v:any)=> v.network == value)
            if(item) {
                this.form1.controls['network'].patchValue(item.network);
                this.form1.controls['dcode'].patchValue(item.dcode);
                return;
            }
        }

        this.form1.controls['dcode'].patchValue('')
    }

    copyAddress(str:string) {
        this.clipboard.copy(str)
        this.copyMessage = '복사 되었습니다.'
    }

    expired() {
        // this.in_progress_crypto = null;
        // this.crypto_info.qrImage = '/assets/images/expired.jpg'
    }

}
