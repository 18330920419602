import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { Subscription, filter, map, pairwise, startWith } from 'rxjs';
import { TimerService } from '../../service/timer.service';
import { CasinoComponent } from '../casino/casino.component';
import { ModalService } from '../../common/modal/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NoticeService } from '../../service/notice.service';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';
import { environment } from '../../environments/environment';
import { fadeIn, fadeOut } from '@app/shared/lib/animation';
// import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ImgLoadedDirective } from '@app/shared/lib/directive/img.directive';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { SettingsService } from '../auth/settings.service';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { EmojiComponent } from '../../common/emoji/emoji.component';
import { GiphyComponent } from '../../common/emoji/giphy.component';
import { LoadingComponent } from '../../common/loading/loading.component';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';
import { DirectMessageComponent } from '../../common/direct-message/direct-message.component';
import { SlotsComponent } from '../slots/slots.component';
import { EventHandlerPayload, LiveChatWidgetModule } from "@livechat/widget-angular";
import { ImgxDirective } from '../../common/imgx.directive';
import { StripTagsPipe } from '@app/shared/lib/pipe/strip-tags.pipe';
import { Nl2brPipe } from '@app/shared/lib/pipe/nl2br.pipe';
import { SiteService } from '../../service/site.service';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectMessageComponent,
        SanitizePipe,
        MomentPipe,
        ImgLoadedDirective,
        ProfileDirective,
        LiveChatWidgetModule,
        ImgxDirective,
        Nl2brPipe,
        SanitizePipe
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './home.component.html',
    styleUrls: [`./home.component.scss`],
    animations:[
        fadeIn,
        fadeOut,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeComponent {
    private subs: Subscription[] = [];
    
    isLoading:boolean=true;
    currentMenu:string = '';
    currentSubMenu:string = '';
    casinoSearch:boolean=false;

    currentUser!:currentUserInfo | null;
    authedTime = 0;
    tokenExpired!:string | null;

    notice_items:any
    quick_top:boolean = false;

    side_nav_bg:boolean = false;
    sideNavFold = false;

    search_keyword:Set<any> = new Set()
    is_searching:boolean = false;
    searchForm!: FormGroup;
    search_items:any = []

    rightOpen_item=""
    tab:string = 'tab-1'
    chat_tab:string = 'notice'
    isDarkMode:boolean = true;
    currency!:string
    currencyInfo = environment.CURRENCY;
    cryptoInfo = environment.CRYPTO;
    search_currency!:string

    isSpin:boolean = false;

    casinoRecomOptions!:SwiperOptions
    casinoRecomItems!:any
    LIVECHAT_LICENSE = environment.LIVECHAT

    telegram_account:string=''

    onModalEvent: EventEmitter<any> = new EventEmitter<any>()

    
    @ViewChild('searchInputEl') searchInputEl!: ElementRef;

    @HostListener('window:scroll', ['$event']) 
    onScroll($event:any){
        this.quick_top = (window.scrollY > 100) ? true : false;
    }

    @HostListener('window:resize', ['$event'])
    onResize($event:any) {
        if($event.target.innerWidth > 1200) {
            this.side_nav_bg = false;
        } else {
            // this.side_nav_bg = this.sideNavFold ? true : false;
            const sideNav = document.querySelector(".side-nav");
            if(sideNav) {
                this.side_nav_bg = !sideNav.classList.contains('fold') ? true : false;
            }
        }
    }

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private timerService: TimerService,
        private modalService: ModalService,
        private noticeService: NoticeService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
        private siteService: SiteService
    ) {
        this.searchForm = this.formBuilder.group({
            keyword: ['', Validators.required]
        });

        this.subs.push(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            ).subscribe((route: ActivatedRouteSnapshot) => {
                const url = this.router.routerState.snapshot.url || ''
                this.fnGetNav(url)

                this.currentSubMenu = route.data['submenu'] || null
            })
        )

        const search_keyword = sessionStorage.getItem('search_keyword');
        if(search_keyword) this.search_keyword = new Set(JSON.parse(search_keyword))
    }

    ngOnInit(): void {
        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                this.isDarkMode = v?.dark_mode ? true : false;
            })
        )

        this.subs.push(
            this.authService.getPairwise().subscribe(([prev, current]) => {
                this.currentUser = current;
                // if(LiveChatWidget) LiveChatWidget.call(`${this.currentUser ? 'hide' : 'minimize'}`)
                if(prev && current) {
                    if(prev.access_token != current.access_token) {

                    }
                }
            })
        )
        

        this.subs.push(
            this.noticeService.getData().subscribe(v => {
                this.notice_items = v;
            })
        )

        // 읽지 않은 쪽지
        this.subs.push(
            this.router.events.subscribe((e: any) => {
                if(e instanceof NavigationEnd) {
                    if(this.currentUser){
                        if(this.currentUser.memo > 0) {
                            this.modalService.open(null, 'memo')
                        }
                    }
                }
            })
        )


        // this.subs.push(
        //     this.router.events.pipe(
        //         filter(event => event instanceof NavigationEnd),
        //         map(() => this.activatedRoute.snapshot),
        //         map(route => {
        //             while (route.firstChild) {
        //                 route = route.firstChild;
        //             }
        //             return route;
        //         })
        //     ).subscribe((route: ActivatedRouteSnapshot) => {
        //         this.currentSubMenu = route.data['sub'] || null
        //     })
        // )

        this.subs.push(
            this.timerService.getDate().subscribe(d => {
                const time = d.unix();
                if(this.authedTime + (10) <= time) {
                    this.authedTime = time;

                    if(this.currentUser) {
                        this.authService.authenticated().subscribe({
                            next: (v:any)=> {
                                if(v.maintain) this.router.navigateByUrl('/upcoming')
                                if(v.emergency > 0) console.log(v.emergency)
                            },
                            error: (err:any) => {
                                console.log(err.statusText)
                                // this.alertService.open(null, err.statusText)
                            }
                        })
                    }
                    
                }
            })
        )

        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                this.currency = v.currency
            })
        )

        this.subs.push(
            this.siteService.getValue().subscribe(v => {
                this.telegram_account = v.telegram;
            })
        )

        this.subs.push(
            this.onModalEvent.subscribe(v => {
                const { component, event, optParam } = v;
                if(event == 'close') {
                    const { next } = optParam;
                    if(next == 'profile'){
                        if(this.currentUser) this.openProfile()
                    }
                    // else if(this.currentUser) this.modal.open(null, optParam.next)
                }
            })
        )

        // this.socketIoService.connect(environment.wsUrl, {
        //     transports: ["websocket"]
        // }).subscribe(v => {
        //     if(v?.cmd == 'latest_bet_init') this.latestBetService.setData(v.data);
        //     else if(v?.cmd == 'high_rollers_init') this.highRollersService.setData(v.data);
        //     else if(v?.cmd == 'bin_won_init') this.bigWonService.setData(v.data);
        //     else if(v?.cmd == 'latest_bet') this.latestBetService.addData(v.data)
        //     else if(v?.cmd == 'high_rollers') this.highRollersService.addData(v.data)
        //     else if(v?.cmd == 'big_won') this.bigWonService.addData(v.data)
        // })
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
        console.log(`ngOnDestroy`)
    }

    ngAfterContentInit() {
        console.log(`ngAfterContentInit`)

        // 페이지 로딩이 시작될 때 실행할 함수
        const showLoadingSvg = () => {
            const loadingContainer = document.getElementById("loadingContainer");
            if(loadingContainer) {
                loadingContainer.style.display = "flex";
            }
        }

        showLoadingSvg()

        // 페이지 로딩이 완료된 후 실행할 함수
        const hideLoadingSvg = () => {
            const loadingContainer = document.getElementById("loadingContainer");
            if(loadingContainer) {
                loadingContainer.style.display = "none";
            }
        }        

        
        
        // 탑바 슬롯 슬라이드
        
        this.isLoading = true;
        hideLoadingSvg();
    }

    fnGoTop() {
        const element = document.getElementById("page-top");
        if(element){
            element.scrollIntoView({ behavior: "smooth",block: "start", inline: "nearest" })
        }    
    }

    loadScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = false;
        body.appendChild(script);
    }

    fnGetNav(url:string): void {
        const sideNavFold = sessionStorage.getItem('sideNavFold')
        this.casinoSearch = false;
        if(url.startsWith('/casino')) {
            this.currentMenu = 'casino';
            // this.sideNavFold = sideNavFold ? true : false
            this.sideNavFold = false;
        } else if(url.startsWith('/slots')) {
            this.currentMenu = 'slots';
            // this.sideNavFold = sideNavFold ? true : false
            this.sideNavFold = false;
        } else if(url.startsWith('/sports/bc')) {
            this.currentMenu = 'bc';
            this.sideNavFold = true
        } else if(url.startsWith('/sports/betradar')) {
            this.currentMenu = 'betradar';
            this.sideNavFold = true            
        } else if(url.startsWith('/sports/esports')) {
            this.currentMenu = 'esports';
            this.sideNavFold = true                        
        } else if(url.startsWith('/vsports')) {
            this.currentMenu = 'vsports';  
            // this.sideNavFold = sideNavFold ? true : false
            this.sideNavFold = false;
        } else if(url.startsWith('/powerball')) {
            this.currentMenu = 'powerball';  
            // this.sideNavFold = sideNavFold ? true : false            
            this.sideNavFold = false;
        } else if(url.startsWith('/minigame')) {
            this.currentMenu = 'minigame';  
            // this.sideNavFold = sideNavFold ? true : false            
            this.sideNavFold = false;
        } else {
            this.currentMenu = ''
            // this.sideNavFold = sideNavFold ? true : false
            this.sideNavFold = false;
        }
    }

     // fold 클래스 체크 함수
    checkFold() {
        const sideMenus = document.querySelectorAll(".side-menu");
        const sideNav = document.querySelector(".side-nav");

        if(sideNav && sideNav.classList.contains("fold")) {
            sideMenus.forEach((menu:any) => {
                if (menu._tippy != undefined) {
                    menu._tippy.enable();
                }
            });
        } else {
            sideMenus.forEach((menu:any) => {
                if (menu._tippy != undefined) {
                    menu._tippy.disable();
                }
            });
        }
    }

    toggleSideNav() {
        this.sideNavFold = !this.sideNavFold
        sessionStorage.setItem('sideNavFold', this.sideNavFold ? '1': '')

        // fold 클래스 변경 체크 함수 호출
        this.checkFold();
    }

    fnCasinoSearch() {
        this.searchForm.controls['keyword'].patchValue('')
        this.search_items = []
        this.casinoSearch = !this.casinoSearch
    }

    fnCasinoSearchClose() {
        this.searchForm.controls['keyword'].patchValue('')
        this.search_items = []
        this.casinoSearch = false;
    }

    componentInit(componentRef:any) {
        // if(!(componentRef instanceof CasinoComponent)) return;
        if(componentRef instanceof CasinoComponent || componentRef instanceof SlotsComponent) {
            this.subs.push(
                componentRef.onSearchBtn.subscribe((v) => {
                    if(v == 'casino') {
                        this.casinoSearch = true
                        const elem = this.searchInputEl.nativeElement
                        if(elem) elem.focus()
                    }
                })
            )
        }
    }

    /**
     * Enter Key만 저장
     */
    onKeyUp($event:any) {
        if($event.keyCode == 13)  {
            const keyword = this.searchForm.controls['keyword'].value
            if(keyword.length < 3) return;

            // this.search_keyword.add(keyword)
            // sessionStorage.setItem('search_keyword', JSON.stringify([...this.search_keyword]))
        }

        this.onSearch()
    }

    onSearch() {
        const keyword = this.searchForm.controls['keyword'].value
        if(keyword.length < 3) {
            this.search_items = []
            return;
        }

        this.apiService.post('/casino/search', { keyword: keyword }).subscribe(jsondata => {
            if(jsondata.success) {
                if(jsondata.data.length > 0) {
                    this.search_keyword.add(keyword)
                    sessionStorage.setItem('search_keyword', JSON.stringify([...this.search_keyword]))
                }

                this.search_items = jsondata.data
            }
        })
    }

    clearSearchHistory(keyword: string = '') {
        if(!keyword) {
            this.search_keyword = new Set()
            sessionStorage.setItem('search_keyword', JSON.stringify([]))
        } else {
            // this.search_keyword.splice(index, 1)
            this.search_keyword.delete(keyword)
            console.log(this.search_keyword)
        }
    }

    rightOpen(target:string='', currentMenu:string=''){
        const content = document.querySelector('.content');
        const right = document.querySelector('.right-area')

        const t = (target == 'notice_area') ? 'chat_area' : target;

        if(target == "close" || target == this.rightOpen_item){
            // 닫기버튼 or 같은버튼을 두번 눌렀을때,
            // content?.classList.remove('right_open')
            // right?.classList.remove('open')

            const use = document.querySelector('.top-bar .chat_btn use')
            if(use) use.setAttribute('xlink:href', '/assets/custom_img/symbol-defs.svg#icon_Chat')

            this.rightOpen_item = "";
            this.currentMenu = currentMenu;
        } else {
            // content?.classList.add('right_open')
            // right?.classList.add('open')

            const el = <HTMLElement>document.querySelector(`.right-area .${t}`)
            if(el) {
                el.style.removeProperty('display')
                const siblings = Array.prototype.slice.call(el.parentNode?.children);
                siblings.forEach((sibling) => {
                    if (sibling !== el) sibling.style.display = 'none';
                })
            }
        
            if(target == "chat_area"){
                this.chat_tab = 'chat'
                const use = document.querySelector('.top-bar .chat_btn use')
                if(use) use.setAttribute('xlink:href', '/assets/custom_img/symbol-defs.svg#icon_CloseChat')
                
            } else if(target == 'notice_area') {
                this.chat_tab = 'notice'
            }

            this.rightOpen_item = target;
            this.currentMenu = 'right_area';
        }
    }

    openModal($event:any, modal:string) {
        if(this.currentUser) this.modal.open($event, modal) 
        else {
            this.modal.open($event, 'sign-in', { next: modal }, this.onModalEvent)
        }
    }

    openProfile($event:any = null) {
        if($event) $event.preventDefault();
        
        if(this.currentUser) {
            this.currentMenu = (this.currentMenu == 'profile') ? '' : 'profile';
            this.rightOpen('close', this.currentMenu)
        } else this.modal.open($event, 'sign-in', { next: 'profile' }, this.onModalEvent)

        
    }

    setDarkMode(isDark:boolean) {
        this.settingsService.setValue({ dark_mode: isDark })
    }

    handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
        console.log("LiveChatWidget.onNewEvent", event);
    }

    PlayGame($event:any, item:any) {
        if($event) $event.preventDefault()

        this.casinoSearch = false;
        if(item['type'] == 'live') {
            this.router.navigateByUrl('/casino/play/' + item['idx'])
        } else {
            this.router.navigateByUrl('/slots/play/' + item['idx'])
        }
    }
}
