import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { LevelIconDirective } from '@app/shared/lib/directive/level-icon.directive';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { Observable, of, Subscription } from 'rxjs';
import { ApiService } from '../../../service/api.service';
import { ShortNumbersPipe } from '@app/shared/lib/pipe/short-numbers';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';

@Component({
    selector: 'app-profile-modal',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        LevelIconDirective,
        ProfileDirective,
        ShortNumbersPipe,
        MomentPipe
    ],
    templateUrl: './profile-modal.component.html',
    styles: ``
})
export class ProfileModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    modalTab = ''

    uidx!:number | undefined;
    profile!: Observable<any>;

    currentUser!: currentUserInfo | null
    isLoading:boolean = true;
    
    constructor(
        private authService: AuthService,
        private apiService: ApiService                  
    ) {}

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v
                // 본인 프로필
                if(this.currentUser && !this.uidx) {
                    this.uidx = this.currentUser.idx;
                    this.fnGetData()
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['options']) {
            const prev = changes['options'].previousValue;
            const current = changes['options'].currentValue
            if(current) {
                this.uidx = current.uidx
                this.fnGetData()
            } 
        }
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get('/user/profile/' + this.uidx).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.profile = of(jsondata.data);
                }
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}
