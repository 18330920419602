<div class="updating_wrap scrollbar flex">
    <img class="h-8 md:hidden block mx-auto" src="/assets/custom_img/kbc_logo.gif" alt="">
    <div class="updating_cont p-4 md:p-8 bg-stand rounded-lg">
        <div class="items-center md:flex hidden">
            <img class="h-8" src="/assets/custom_img/kbc_logo.gif" alt="">
            <a href="javascript:;" class="flex items-center bg-backlight justify-center px-2 py-1 gap-1">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path fill="#3BC117" d="M11.155 17.247c1.147 0 2.077 0.93 2.077 2.077v8.099c0 1.147-0.93 2.077-2.077 2.077h-2.178c-1.721 0-3.115-1.395-3.115-3.115v-6.022c0-1.721 1.395-3.115 3.115-3.115h2.178zM23.112 17.247c1.721 0 3.115 1.395 3.115 3.115v0 6.022c0 1.721-1.395 3.115-3.115 3.115v0h-2.178c-1.147 0-2.077-0.93-2.077-2.077v0-8.099c0-1.147 0.93-2.077 2.077-2.077v0zM16 2.5c8.19 0 14.853 6.518 15.053 14.634l0.005 0.376v6.131c0 0.846-0.688 1.532-1.537 1.532-0.796 0-1.45-0.603-1.529-1.375l-0.008-0.157v-6.131c0-6.598-5.366-11.946-11.985-11.946-6.507 0-11.802 5.169-11.98 11.612l-0.005 0.334v6.15c0 0.846-0.688 1.532-1.537 1.532-0.796 0-1.45-0.603-1.528-1.375l-0.008-0.157v-6.15c0-8.289 6.742-15.009 15.058-15.009z"></path>
                </svg>
                실시간 지원
            </a>
        </div>
        <div class="flex flex-col-reverse md:flex-row items-center mb-10 md:text-sm text-xs">
            <div style="min-width:350px;">
                <div class="mt-1 md:text-left text-center">
                    <b class="md:inline-flex block text-white text-2xl">{{ countdown }}</b>
                </div>
                <div class="md:hidden flex justify-center mt-2">
                    <a href="javascript:;" class="flex items-center bg-backlight justify-center px-2 py-1 gap-1">
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path fill="#3BC117" d="M11.155 17.247c1.147 0 2.077 0.93 2.077 2.077v8.099c0 1.147-0.93 2.077-2.077 2.077h-2.178c-1.721 0-3.115-1.395-3.115-3.115v-6.022c0-1.721 1.395-3.115 3.115-3.115h2.178zM23.112 17.247c1.721 0 3.115 1.395 3.115 3.115v0 6.022c0 1.721-1.395 3.115-3.115 3.115v0h-2.178c-1.147 0-2.077-0.93-2.077-2.077v0-8.099c0-1.147 0.93-2.077 2.077-2.077v0zM16 2.5c8.19 0 14.853 6.518 15.053 14.634l0.005 0.376v6.131c0 0.846-0.688 1.532-1.537 1.532-0.796 0-1.45-0.603-1.529-1.375l-0.008-0.157v-6.131c0-6.598-5.366-11.946-11.985-11.946-6.507 0-11.802 5.169-11.98 11.612l-0.005 0.334v6.15c0 0.846-0.688 1.532-1.537 1.532-0.796 0-1.45-0.603-1.528-1.375l-0.008-0.157v-6.15c0-8.289 6.742-15.009 15.058-15.009z"></path>
                        </svg>
                        실시간 지원
                    </a>
                </div>
                <div class="mt-5">
                    <span [innerHTML]="ment | nl2br"></span>
                </div>
            </div>
            <div>
                <img src="/assets/custom_img/updating.png" alt="">
            </div>
        </div>
    </div>
</div>