import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-notice-modal',
  standalone: true,
  imports: [],
  templateUrl: './notice-modal.component.html',
  styles: ``
})
export class NoticeModalComponent {
    @Input() optParam:any = {}

    title = `공지 사항`
    content = ``
    constructor() {
    }
    

    ngOnChanges(changes: SimpleChanges) {
        if(changes['optParam']) {
            const prev = changes['optParam'].previousValue;
            const current = changes['optParam'].currentValue
            if(current) {
                if(current['title']) this.title = current['title']
            }
        }
    }
}
