import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ModalService } from '../../common/modal/modal.service';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-vip',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './vip.component.html',
  styles: ``
})
export class VipComponent {
    private subs: Subscription[] = [];
    
    currentUser!:currentUserInfo | null;
    
    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private authService: AuthService
    ){}

    ngOnInit(): void {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    openModal($event:any, modal:string) {
        if(this.currentUser) this.modal.open($event, modal) 
        else {
            this.modal.open($event, 'sign-in', { next: modal })
        }
    }
}
