import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TimerService } from '../../service/timer.service';
import { Subscription } from 'rxjs';
import moment from 'moment-timezone';
import { ApiService } from '../../service/api.service';
import { Nl2brPipe } from '@app/shared/lib/pipe/nl2br.pipe';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';

@Component({
    selector: 'app-upcoming',
    standalone: true,
    imports: [
        CommonModule,
        Nl2brPipe,
        SanitizePipe
    ],
    templateUrl: './upcoming.component.html',
    styles: ``
})
export class UpcomingComponent {
    private subs: Subscription[] = [];
    
    evt_btn:boolean = false
    uptime:moment.Moment | null = null
    countdown:string = ''
    ment:string = '현재 시스템을 업그레이드하는 중이므로 진심으로 사과드립니다.'
    constructor(
        private timerService: TimerService,
        private apiService: ApiService
    ) {
        // this.uptime = moment().add(1, `minutes`)
    }

    ngOnInit(): void {
        this.subs.push(
            this.timerService.getDate().subscribe(d => {

                    const diff = (this.uptime) ? this.uptime.diff(d, `seconds`) : 0;

                    if(diff > 0) {
                        const mind = diff % (60 * 60);
                        const hours = Math.floor(diff / (60 * 60));
                        const minutes = Math.floor(mind / 60);
                        const seconds = Math.ceil(mind % 60);

                        this.countdown = `${hours.toString().padStart(2, `0`)}:${minutes.toString().padStart(2, `0`)}:${seconds.toString().padStart(2, `0`)}`
                    } else {
                        this.countdown = `Comming Soon...`
                    }
            })
        )

        this.fnGetData()
    }

    fnGetData() {
        this.apiService.get('/site').subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.uptime = (jsondata.data.maintain_time) ? moment.tz(jsondata.data.maintain_time ,'Asia/Seoul') : null
                    if(jsondata.data.maintain_ment) this.ment = jsondata.data.maintain_ment
                }
            }, error: (err) => {

            }
        })
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    btnEvent() {
        this.evt_btn = !this.evt_btn
    }
}
