import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingComponent } from '../../common/loading/loading.component';
import { PaginationComponent } from '../../common/pagination/pagination.component';
import { ApiService } from '../../service/api.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-memo-list',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        PaginationComponent,
        MomentPipe
    ],
    templateUrl: './memo-list.component.html',
    styles: ``
})
export class MemoListComponent {
    @Input() page!:number;
    @Output() onRead: EventEmitter<number> = new EventEmitter<number>();

    memo = {
        items:[],
        items_total: 0
    }

    MEMO_STATUS = environment.MEMO_STATUS
    MEMO_STATUS_TEXT = environment.MEMO_STATUS_TEXT
    isLoading:boolean = true;
    constructor(
        private apiService: ApiService,
    ) {}

    ngOnInit() {
        this.fnGetData()
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.post('/memo/list', {page:this.page}).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.memo = jsondata.data
                }
                
            },error: (err) => {
                this.isLoading = false;
            }
        })
    }

    read(idx:number) {
        this.onRead.emit(idx)
    }

    read_all() {
        this.isLoading = true;
        this.apiService.post('/memo/read-all').subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.message) (window as any).alert(jsondata.message)
                if(jsondata.success) this.fnGetData();
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    GoPage(page:number) {
        this.page = page;
        this.fnGetData()
    }

    MemoDelete(num:any) {
        const params = {
            all: false,
            idx: null,
        }

        if(num == 'all') params['all'] = true;
        else params['idx'] = num;

        this.isLoading = true;
        this.apiService.post('/memo/remove', params).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.message) (window as any).alert(jsondata.message)
                if(jsondata.success) this.fnGetData();
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }
}
