import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalService } from '../../../common/modal/modal.service';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { ApiService } from '../../../service/api.service';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';

@Component({
  selector: 'app-spin-modal',
  standalone: true,
  imports: [
    CommonModule,
    SwiperDirective,
    ProfileDirective
  ],
  templateUrl: './spin-modal.component.html',
  styles: ``,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SpinModalComponent {
    private subs: Subscription[] = [];
    
    modalTab = ''
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    onModalEvent: EventEmitter<any> = new EventEmitter<any>()

    currentUser!: currentUserInfo | null
    total_hit!:number

    sigining:boolean = false;
    spin = 'LUCKY'
    spinColor:any = {
        LUCKY: 'rgb(101, 49, 32)',
        SUPER: 'rgb(235, 145, 6)',
        MEGA: 'rgb(105, 14, 224)'
    };
    spinText:any = {
        LUCKY: '브론즈',
        SUPER: '골드',
        MEGA: '다이아몬드'
    };
    spinLevel:any = {
        LUCKY:'브론즈 레벨 이상',
        SUPER:'골드 레벨 이상',
        MEGA: '다이아1 레벨 이상'
    }
    spinData = {
        LUCKY: [
            { code: 'KRW', amount: 1000, rotate: 45 },
            { code: 'KRW', amount: 3000, rotate: 135 },
            { code: 'KRW', amount: 5000, rotate: 225 },
            { code: 'KRW', amount: 10000, rotate: 337.5 },
            { code: 'KRW', amount: 25000, rotate: 292.5 },
            { code: 'KRW', amount: 50000, rotate: 22.5 },
            { code: 'KRW', amount: 100000, rotate: 67.5 },
            { code: 'KRW', amount: 300000, rotate: 180 },
            { code: 'XRP', amount: 100, rotate: 315 },
            { code: 'DOGE', amount: 2500, rotate: 157.5 },
            { code: 'ETH', amount: 3, rotate: 270 },
            { code: 'ETH', amount: 1, rotate: 202.5 },
            { code: 'USDT', amount: 50, rotate: 112.5 },
            { code: 'USDT', amount: 1000, rotate: 247.5 },
            { code: 'BTC', amount: 0.001, rotate: 90 },
            { code: 'BTC', amount: 1, rotate: 0 },
        ],
        SUPER: [
            { code: 'KRW', amount: 3000, rotate: 45 },
            { code: 'KRW', amount: 5000, rotate: 135 },
            { code: 'KRW', amount: 10000, rotate: 225 },
            { code: 'KRW', amount: 25000, rotate: 337.5 },
            { code: 'KRW', amount: 50000, rotate: 292.5 },
            { code: 'KRW', amount: 100000, rotate: 22.5 },
            { code: 'KRW', amount: 250000, rotate: 67.5 },
            { code: 'KRW', amount: 1000000, rotate: 180 },
            { code: 'XRP', amount: 300, rotate: 315 },
            { code: 'DOGE', amount: 7500, rotate: 157.5 },
            { code: 'ETH', amount: 0.9, rotate: 270 },
            { code: 'ETH', amount: 3, rotate: 202.5 },
            { code: 'USDT', amount: 150, rotate: 112.5 },
            { code: 'USDT', amount: 3000, rotate: 247.5 },
            { code: 'BTC', amount: 0.003, rotate: 90 },
            { code: 'BTC', amount: 3, rotate: 0 },
        ],
        MEGA: [
            { code: 'KRW', amount: 5000, rotate: 45 },
            { code: 'KRW', amount: 10000, rotate: 135 },
            { code: 'KRW', amount: 25000, rotate: 225 },
            { code: 'KRW', amount: 50000, rotate: 337.5 },
            { code: 'KRW', amount: 100000, rotate: 292.5 },
            { code: 'KRW', amount: 250000, rotate: 22.5 },
            { code: 'KRW', amount: 1000000, rotate: 67.5 },
            { code: 'KRW', amount: 5000000, rotate: 180 },
            { code: 'XRP', amount: 500, rotate: 315 },
            { code: 'DOGE', amount: 12500, rotate: 157.5 },
            { code: 'ETH', amount: 1.5, rotate: 270 },
            { code: 'ETH', amount: 5, rotate: 202.5 },
            { code: 'USDT', amount: 2500, rotate: 112.5 },
            { code: 'USDT', amount: 5000, rotate: 247.5 },
            { code: 'BTC', amount: 0.005, rotate: 90 },
            { code: 'BTC', amount: 5, rotate: 0 },
        ]
    }
    current_position = 1440;

    errMessage!:string;

    swiperOptions!:SwiperOptions
    swiperItems:any = []
    topItem:any;
    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private apiService: ApiService,
    ) {
        this.swiperOptions = {
            direction: "vertical",
            slidesPerView: 16,
            loop: true,
            autoplay:{
                delay: 500,
                reverseDirection: false,
                disableOnInteraction: false,
            },
        };
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
                if(v) {
                    if(v.level == 1 || v.level == 2 || v.level == 8 || v.level == 11) this.spin = 'LUCKY';
                    else if(v.level ==  3 || v.level == 4 || v.level == 5 || v.level == 10) this.spin = 'SUPER';
                    else if(v.level == 6 || v.level ==7) this.spin = 'MEGA'
                }
            })
        )

        this.subs.push(
            this.onModalEvent.subscribe(v => {
                const { component, event, optParam } = v;
                if(event == 'close') {
                    // auto play
                    // if(this.currentUser) this.rollingSpin(optParam.spin)
                }
            })
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngAfterViewInit() {
        const els = document.querySelectorAll(`.spin-light`)

        setInterval(()=>{
            els.forEach(el => {
                el.classList.toggle('active')
            })
        }, 500)
    }

    fnGetData() {
        this.apiService.get('/spin/article').subscribe(jsondata => {
            if(jsondata.success) {
                this.swiperItems = []
                this.total_hit = jsondata.data.total_hit
                this.topItem = jsondata.data.top;
                jsondata.data.items.map((v:any) => {
                    this.swiperItems.push({
                        nick: v.nick,
                        spin: v.spin,
                        amount: v.hit_amount,
                        wallet_code: v.wallet_code
                    })
                })
            }
        })
    }

    rollingSpin(name:string): void {
        if(!this.currentUser) this.modalService.open(null, 'sign-in', { spin: name }, this.onModalEvent)
        else this.play(name)
    }

    play(name:string): void {
        this.sigining = true;
        this.apiService.post('/spin/play', { spin: name }).subscribe({
            next: (jsondata) => {
                this.sigining = false;
                if(jsondata.message) this.errMessage = jsondata.message
                if(jsondata.success) {
                    const rank = jsondata.data.win;
                    const wallet_code = jsondata.data.wallet_code;
                    const hit_amount = jsondata.data.hit_amount;
                    this.rolling(name, rank, wallet_code, hit_amount)
                }
            }, error: (err) => {
                this.sigining = false;
            }
        })
        
    }

    rolling(name:string, rank:number, wallet_code:string, hit_amount:number,): void {
        const el = document.querySelector(`.${name}`)
        if(el) {
            const zoom = <HTMLElement>el.querySelector('.zoom_box')
            if(zoom) {
                let data:any;
                zoom.classList.add('rolling')
                if(name == 'spin_bronze') {
                    const rotate = this.spinData.LUCKY[16 - rank].rotate;
                    const position = this.current_position - rotate;
                    this.current_position = this.current_position + 1440;
                    zoom.style.transform = `scale(1) rotate(${position}deg)`
                } else if(name == 'spin_gold') {
                    const rotate = this.spinData.SUPER[16 - rank].rotate;
                    const position = this.current_position - rotate;
                    this.current_position = this.current_position + 1440;
                    zoom.style.transform = `scale(1) rotate(${position}deg)`
                } else if(name == 'spin_diamond') {
                    const rotate = this.spinData.MEGA[16 - rank].rotate;
                    const position = this.current_position - rotate;
                    this.current_position = this.current_position + 1440;
                    zoom.style.transform = `scale(1) rotate(${position}deg)`
                }

                setTimeout(() => {
                    this.sigining = false;
                    // this.onClose.emit('close')
                    const next = (name == "spin_diamond") ? `spin-rolling2` : `spin-rolling`
                    this.modalService.open(null, next, { code:wallet_code, amount: hit_amount })
                }, 3500)
            }
        }
    }

    

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
        this.current_position = 1440; // 위치 초기화
    }
}

