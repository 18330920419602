import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../common/loading/loading.component';
import { DepositCurrencyComponent } from './deposit-currency.component';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { DepositCryptoComponent } from './deposit-crypto.component';

@Component({
  selector: 'app-deposit',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    DepositCurrencyComponent,
    DepositCryptoComponent
],
  templateUrl: './deposit.component.html',
  styles: ``
})
export class DepositComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    currentUser!:currentUserInfo | null

    is_promotion:boolean = false;
    is_crypto_user:boolean = false;
    isLoading:boolean = false;
    constructor(
        private authService: AuthService
    ) {
        
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
                if(v) this.is_crypto_user = v['is_crypto_user'] ? true : false;
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['options']) {
            const prev = changes['options'].previousValue;
            const current = changes['options'].currentValue
            if(current) {
                if(current['promotion_code']) this.is_promotion = true;
            }
        }
    }
}
