import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../common/modal/modal.service';
import { ApiService } from '../../../service/api.service';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../../common/loading/loading.component';
import moment from 'moment-timezone';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';


@Component({
    selector: 'app-racing-rank-modal',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        MomentPipe,
        ProfileDirective
    ],
    templateUrl: './racing-rank-modal.component.html',
    styles: ``
})
export class RacingRankModalComponent {
    
    
    historyItems:any
    date!:string;
    medalImg = [
        '/assets/custom_img/chat_gold.svg',
        '/assets/custom_img/chat_silver.svg',
        '/assets/custom_img/chat_copper.svg',
    ]

    isLoading:boolean = true;

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private apiService: ApiService
    ) {
        this.date = moment.tz('Asia/Seoul').add(`-1`, `days`).format('YYYY-MM-DD')
    }

    ngOnInit() {
        this.fnGetData()
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get(`/promotion/contest-history`).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.historyItems = jsondata.data.items;
                }

                this.isLoading = false;
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }
}
