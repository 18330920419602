import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingComponent } from '../../common/loading/loading.component';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { WithdrawCurrencyComponent } from './withdraw-currency.component';
import { WithdrawCryptoComponent } from './withdraw-crypto.component';

@Component({
    selector: 'app-withdraw',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        WithdrawCurrencyComponent,
        WithdrawCryptoComponent
    ],
    templateUrl: './withdraw.component.html',
    styles: ``
})
export class WithdrawComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    currentUser!:currentUserInfo | null

    is_crypto_user:boolean = false;
    isLoading:boolean = false;
    constructor(
        private authService: AuthService
    ) {
        
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
                if(v) this.is_crypto_user = v['is_crypto_user'] ? true : false;
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
