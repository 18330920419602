import { Component } from '@angular/core';

@Component({
  selector: 'app-redirest',
  standalone: true,
  imports: [],
  template: '',
  styles: ``
})
export class RedirestComponent {
    constructor() {}

    ngOnInit() {
        // const iframe = window.self !== window.top;
        (window as any).top.location = '/';
    }
}



// link 생성할 때 저장 (로그인 어디로 보낼건지)

// redirect = @sid