import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../common/modal/modal.service';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { LatestBetService } from '../../service/latest-bet.service';
import { HighRollersService } from '../../service/high-rollers.service';
import { PromotionContestComponent } from "../promotion/promotion-contest.component";
import { EllipsisPipe } from '@app/shared/lib/pipe/ellipsis.pipe';
import moment, { Moment } from 'moment';

@Component({
    selector: 'app-contest',
    standalone: true,
    imports: [
    CommonModule,
    PromotionContestComponent,    
    EllipsisPipe
],
    templateUrl: './contest.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContestComponent {
    private subs: Subscription[] = [];
    
    currentUser!:currentUserInfo | null;
    
    tab:string = 'latest_bets'

    latestBetItems:any = [];
    latestBetQueue:any = [];
    latestBet:any = []

    highRollersItems:any = [];
    highRollersQueue:any = [];
    highRollers:any = [];
    

    everySecond: Observable<number> = timer(0, 200);
    MAX_SIZE:number = 12;
    
    @ViewChild('flipdownDiv') flipdownDiv!: ElementRef;

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private authService: AuthService,
        private latestBetService: LatestBetService,
        private highRollersService: HighRollersService
    ) {

    }

    ngOnInit() {
        this.subs.push(
            this.latestBetService.getData().subscribe(items => {
                if(items) {
                    this.latestBet = []
                    items.map(item => {
                        this.latestBet.push(item); // 초기값
                        const size = this.MAX_SIZE - this.latestBetItems.length
                        if(size > 0) this.latestBetItems.push(item)
                        else {
                            this.latestBetQueue.push(item)
                        }
                    })
                }
            })
        )

        this.subs.push(
            this.highRollersService.getData().subscribe(items => {
                if(items) {
                    this.highRollers = []
                    items.map(item => {
                        this.highRollers.push(item);    // 초기값
                        const size = this.MAX_SIZE - this.highRollersItems.length
                        if(size > 0) this.highRollersItems.push(item)
                        else {
                            this.highRollersQueue.push(item)
                        }
                    })
                }
            })
        )

        this.subs.push(
            this.everySecond.subscribe(v => {
                const checked = Math.random() < 0.3; // 30% probability of getting true
                if(!checked) return;

                if(this.latestBetQueue.length > 0) {
                    this.latestBetItems.pop()
                    const item = this.latestBetQueue[0]
                    this.latestBetQueue.shift()
                    this.latestBetItems.unshift(item)
                } else {
                    if(this.latestBet.length > 0) { // 초기값
                        this.latestBet.map((item:any) => {
                            this.latestBetQueue.push(item)
                        })
                    }
                }

                if(this.highRollersQueue.length > 0) {
                    this.highRollersItems.pop()
                    const item = this.highRollersQueue[0]
                    this.highRollersQueue.shift()
                    this.highRollersItems.unshift(item)
                } else {
                    if(this.highRollers.length > 0) { // 초기값
                        this.highRollers.map((item:any) => {
                            this.highRollersQueue.push(item)
                        })
                    }
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    onTab(tab:string){
        this.tab = tab;
    }

    GetGamesIcon(code:string, game_name:string='') {
        if(code == 'BET_CONSTRUCT') return '/assets/images/category/sports.svg';
        else if(code == 'BETRADAR') {
            if(game_name.startsWith('958:esport')) return '/assets/images/category/esports.svg'
            else return '/assets/images/category/sports.svg'
        } else if(code == 'CASINO' || code == 'EVOLUTION') return '/assets/images/category/casino.svg';
        else if(code == 'MINIGAME' || code == 'WP') return '/assets/images/category/minigame.svg'
        else console.log(code)
        return ''
    }
}
