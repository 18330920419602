<!-- 프로필 - 추천 그리고 보상 -->
<div class="modal-content">
    <ng-template [ngIf]="isLoading">
        <app-loading />
    </ng-template>
    <div class="modal-head">
        <div class="flex items-center justify-between p-4">
            <p class="text-tit font-extrabold text-base">친구 추천하기</p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
    </div>
    <div class="modal-body rounded overflow-hidden dark:bg-back2 bg-white">
        <div class="p-5">
            <div class="flex items-center gap-4 bg-gradient-green h-40">
                <img class="w-1/3" src="/assets/custom_img/profile/refer.webp" alt="">
                <div class="text-tit text-lg font-extrabold text-left">
                    <p>친구를 초대하고</p>
                    <p><b class="text-2xl text-yellow">최대 1,450,000 KRW</b></p>
                    <p>커미션 받기</p>
                </div>
            </div>
            <!-- <div class="my-2 text-center">
                <a href="javascript:;" class="text-sub underline">더 알아보기</a>
            </div> -->
            <div class="p-3 text-left">
                <p class="pl-4">당신의 추천인 링크를 공유하세요:</p>
                <div class="border border-solid border-slate-300 bg-white dark:bg-back2 mt-2 p-2 flex items-center justify-center">
                    <input type="text" class="form-control form-control-rounded !bg-transparent" disabled [value]="referral_url">
                    <button class="w-28 h-9 border-none text-tit bg-slate-300 rounded font-extrabold" (click)="copyAddress()" [disabled]="referral_url == '관리자에게 문의 하세요.'">링크복사</button>
                </div>
            </div>
        </div>
    </div>
</div>
