<ng-container *ngFor="let item of popup.items">
    <div class="popup-container" [attr.id]="item['idx']" @fadeIn @fadeOut>
        <div class="close-button text-right mr-2 mt-1"> 
            <button (click)="close(item['idx'])" class="basic-hover">
                <svg class="w-4 h-4 cursor-pointer hover:-rotate-90 duration-500 transition-all">
                    <use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use>
                </svg>
            </button>
        </div>
        <div (click)="close(item['idx'])" role="button">
            <img [src]="item.image1" class="w-100" imgx />
        </div>
        <div class="p-1">
            <label class="form-check-label" role="button">
                <input type="checkbox" class="form-check-input" [(ngModel)]="item.hide_day" role="button">
                <span class="ml-1">24시간동안 보지 않기</span>
            </label>
        </div>
        <div class="close-button text-center"> 
            <button (click)="close(item['idx'])">
                [ 닫기 ]
            </button>
        </div>
    </div>
</ng-container>