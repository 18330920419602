import { Component, EventEmitter, Output } from '@angular/core';
import { LoadingComponent } from '../../common/loading/loading.component';
import { CommonModule } from '@angular/common';
import { SafeboxInComponent } from './safebox-in.component';
import { SafeboxOutComponent } from './safebox-out.component';
// import { SafeboxTransactionComponent } from './safebox-transaction.component';

@Component({
    selector: 'app-safebox',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        SafeboxInComponent,
        SafeboxOutComponent,
        // SafeboxTransactionComponent
    ],
    templateUrl: './safebox.component.html',
    styles: ``
})
export class SafeboxComponent {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    tab:string='in'
    isLoading:boolean = false;


    constructor() {}


}
