import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
    private currentValue:BehaviorSubject<any> = new BehaviorSubject<any>({
        telegram: ``,
    });

    constructor() {}

    public getValue() {
        return this.currentValue.asObservable() 
    }

    public setValue(options:any) {
        const value = { ...this.currentValue.getValue(), ...options};
        this.currentValue.next(value);
    }
}