import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface currentSettingsInfo {
    lang: string;
    currency: string;
    dark_mode: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
    public currentValue:BehaviorSubject<currentSettingsInfo> = new BehaviorSubject<currentSettingsInfo>({
        lang: `ko`,
        currency: `KRW`,
        dark_mode: true,
    });

    constructor() {}

    public getValue() {
        return this.currentValue.asObservable() 
    }

    public setValue(options:any) {
        const currentValue = { ...this.currentValue.getValue(), ...options};
        this.currentValue.next({
            lang: currentValue.lang,
            currency: currentValue.currency,
            dark_mode: currentValue.dark_mode
        });
    }
}