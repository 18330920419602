import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router  } from '@angular/router';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { environment, environmentOptions } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { GetLevelImage } from '../../../common/common';
import { ModalService } from '../../../common/modal/modal.service';
import { ApiService } from '../../../service/api.service';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';


@Component({
    selector: 'app-vip-club-modal',
    standalone: true,
    imports: [
        CommonModule,
        SanitizePipe
    ],
    templateUrl: './vip-club-modal.component.html',
    styles: ``
})
export class VipClubModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    tab:string = ''

    currentUser!:currentUserInfo | null;
    LEVEL_IMAGE:environmentOptions = environment.LEVEL_IMAGE
    GetLevelImage = GetLevelImage

    next_exp:number = 0;
    current_exp:number = 0;
    bar:number = 0;
    level_name:string=''

    img_bronze = '/assets/custom_img/profile/lock.svg'
    img_silver = '/assets/custom_img/profile/lock.svg'
    img_gold = '/assets/custom_img/profile/lock.svg'
    img_platinum1 = '/assets/custom_img/profile/lock.svg'
    img_platinum2 = '/assets/custom_img/profile/lock.svg'
    img_diamond1 = '/assets/custom_img/profile/lock.svg'
    img_diamond2 = '/assets/custom_img/profile/lock.svg'


    constructor(
        private router: Router,
        private authService: AuthService,
        private modalService: ModalService,
        private apiService: ApiService
    ) {
        
    }

    ngOnInit(): void {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
                if(!this.tab) {
                    if(this.currentUser) {
                        if(this.currentUser.level == 1 || this.currentUser.level == 8) {
                            this.level_name = this.tab = 'bronze'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 2) {
                            this.level_name = this.tab = 'silver'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 3 || this.currentUser.level == 10) {
                            this.level_name = this.tab = 'gold'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                            this.img_gold = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 4) {
                            this.level_name = this.tab = 'platinum-I'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                            this.img_gold = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum1 = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 5) {
                            this.level_name = this.tab = 'platinum-II'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                            this.img_gold = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum1 = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum2 = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 6) {
                            this.level_name = this.tab = 'diamond-I'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                            this.img_gold = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum1 = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum2 = '/assets/custom_img/profile/unlock.svg'
                            this.img_diamond1 = '/assets/custom_img/profile/unlock.svg'
                        } else if(this.currentUser.level == 7) {
                            this.level_name = this.tab = 'diamond-II'
                            this.img_bronze = '/assets/custom_img/profile/unlock.svg'
                            this.img_silver = '/assets/custom_img/profile/unlock.svg'
                            this.img_gold = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum1 = '/assets/custom_img/profile/unlock.svg'
                            this.img_platinum2 = '/assets/custom_img/profile/unlock.svg'
                            this.img_diamond1 = '/assets/custom_img/profile/unlock.svg'
                            this.img_diamond2 = '/assets/custom_img/profile/unlock.svg'
                        }
                    } else {
                        this.tab == 'bronze'
                    }
                }
                
            })
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.apiService.get('/user/vip').subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.next_exp = jsondata.data.next_exp;
                    this.current_exp = jsondata.data.current_exp;
                    this.bar = jsondata.data.bar;
                }
            }, error: (err) => {

            }
        })
    }
    
    Go(url:string, $event:any = null): void {
        if($event) $event.preventDefault();

        this.router.navigateByUrl(url)
        this.onClose.emit()
    }

    GoModal(modal:string, $event:any = null): void {
        if($event) $event.preventDefault();

        this.onClose.emit()
        this.modalService.open(null, modal)
    }
}
