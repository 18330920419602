import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModalService } from '../../common/modal/modal.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Swiper, SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { SwiperNoticeComponent } from './swiper-notice.component';
import { PopupComponent } from '../../common/popup/popup.component';
import { ContestComponent } from './contest.component';
import { ApiService } from '../../service/api.service';
import { ImgxDirective } from '../../common/imgx.directive';
import { NgxFastMarqueeModule } from 'ngx-fast-marquee';
import { EllipsisPipe } from '@app/shared/lib/pipe/ellipsis.pipe';


@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SwiperNoticeComponent,
        ContestComponent,
        SwiperDirective,
        PopupComponent,
        ImgxDirective,
        NgxFastMarqueeModule,
        NgOptimizedImage,
        EllipsisPipe
    ],
    templateUrl: './main.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;

    bigWonItems:any = []

    liveCasinoOptions!:SwiperOptions
    liveCasinoItems!:any

    slotLobbyOptions!:SwiperOptions
    slotLobbyItems!:any

    kbcgameOptions!:SwiperOptions
    kbcgameItems!:any

    isLoading:boolean = false;

    max_bonus!:number;

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private router: Router,
        private apiService: ApiService
    ) {
        
    }

    ngOnInit(): void {
        // onSameUrlNavigation
        this.subs.push(
            this.router.events.subscribe((e: any) => {
                if(e instanceof NavigationEnd) {
                    this.fnGetData()
                }
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        // 라이브 카지노
        this.liveCasinoOptions = {
            slidesPerView: 4,
            spaceBetween: 10,
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
                815:{
                    slidesPerView: 4,
                },
                990:{
                    slidesPerView: 5,
                },
                1100:{
                    slidesPerView: 6,
                },
                1335:{
                    slidesPerView: 7,
                }
            },
            navigation: {
                nextEl: ".casino_con02 .swiper-next",
                prevEl: ".casino_con02 .swiper-prev",
            },
        }

        this.slotLobbyOptions = { ...this.liveCasinoOptions, navigation: { nextEl: ".casino_con03 .swiper-next", prevEl: ".casino_con03 .swiper-prev" }};
        this.kbcgameOptions =  { ...this.liveCasinoOptions, navigation: { nextEl: ".casino_con04 .swiper-next", prevEl: ".casino_con04 .swiper-prev" }};

        this.fnGetData();
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }


    fnGetData() {
        this.isLoading = true;
        this.apiService.get('/main').subscribe({
            next: (jsondata:any) => {
                this.isLoading = false;
                if(jsondata.success)  {
                    for (const [key, value] of Object.entries(jsondata.data)) {
                        if(key == 'live_casino') this.liveCasinoItems = value;
                        else if(key == 'slot_lobby') this.slotLobbyItems =value;
                        else if(key == 'kbcgame') this.kbcgameItems = value;
                        else if(key == 'max_bonus') this.max_bonus = Number(value);
                        else if(key == 'big_won') this.bigWonItems = value;
                    }
                }
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
