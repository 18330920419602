import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TurnstileComponent } from '@app/shared/lib/turnstile/turnstile.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../../service/api.service';
import { ValidatorService } from '../../../service/validator.service';
import { passwordView } from '../../../common/common';
import { ModalService } from '../../../common/modal/modal.service';
import { CountdownDirective } from '@app/shared/lib/directive/countdown.directive';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';

@Component({
    selector: 'app-sign-up-secret-modal',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TurnstileComponent,
        ReactiveFormsModule,
        CountdownDirective,
        LoadingComponent,
        FilterPipe,
        NgSelectDirective
    ],
    templateUrl: './sign-up-secret-modal.component.html',
    styles: ``
})
export class SignUpSecretModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    siginUpForm!: FormGroup;
    sigining = false;
    onDuplicating_uid = false; // 아이디 중복확인
    onDuplicating_nick = false; // 닉네임 중복확인
    submitted = false;
    returnUrl: string;
    hostname: string = '';
    error = '';
    ok = {
        uid: ``,
        nick: ``,
    }

    keyword:string=''
    selectedCrypto = 'USDT'
    selectedNetwork = 'ERC-20'
    selectedNetworks:any = ['ERC-20', 'TRC-20', 'BEP-20']
    
    crypto_codes:any = []
    crypto_networks:any = [
        { code: 'ERC-20', value: 'ERC-20' },
        { code: 'TRC-20', value: 'TRC-20' },
        { code: 'BEP-20', value: 'BEP-20' },
    ]
    crypto_items:any; // 크립토 리스트
    
    referral_code:string=''

    siteKey = environment.CLOUDFLARE.TURNSTILE.SITE_KEY;

    recaptchaReady = false;

    uid_error:string | null = null;
    nick_error:string | null = null;

    tab:string='tab1'

    telegram_account:string=''
    isLoading:boolean = false;

    get f() { return this.siginUpForm.controls; }
    get passwordView() { return passwordView }

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private apiService: ApiService,
        private validatorService: ValidatorService,
        private modalService: ModalService
    ) {
        this.siginUpForm = this.formBuilder.group({
            uid: ['', Validators.required],
            nick: ['', Validators.required],
            telegram: ['', Validators.required],
            pwd: ['', Validators.required],
            pwd_confirm: ['', Validators.required, this.validatorService.compare('pwd')],
            crypto_exchange:['', Validators.required],
            crypto_code:['USDT', Validators.required],
            crypto_network:['ERC-20', Validators.required],
            crypto_dcode: ['USDT', Validators.required],
            crypto_address: ['', Validators.required],
            referral_code: [''],
            agree_policy: ['', Validators.required],
            agree_promotion: [''],
            'cf-turnstile-response': ['']
        });

        this.returnUrl = sessionStorage.getItem('returnUrl') || '/';
        this.hostname = window.location.hostname.replace('www.', '')

        this.clearStorage();
    }

    ngOnInit(): void {
        const referral_code = sessionStorage.getItem('referral')
        if(referral_code) this.f['referral_code'].patchValue(referral_code)

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get('/site/info-signup-secret').subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                this.telegram_account = jsondata.data.telegram_account;
                jsondata.data.crypto_items.map((item:any) => {
                    const k = this.crypto_codes.find((v:any) => v.code == item.code)
                    if(!k) this.crypto_codes.push({ code: item.code, value: item.name })
                    
                })
                this.crypto_items = jsondata.data.crypto_items;

            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    sendCaptchaResponse(captchaResponse: any) {
        this.recaptchaReady = true;
        this.f['cf-turnstile-response'].patchValue(captchaResponse)
    }

    clearStorage() {
        const signout = sessionStorage.getItem('signout');
        if(signout) {
            const d = JSON.parse(signout);
            this.error = d.message;

            sessionStorage.removeItem('signout');
        }
        
        this.authService.clear();
    }

    onSignUp() {
        this.submitted = true;
        if(this.siginUpForm.invalid) {
            if(this.uid_error || this.f['uid'].errors || this.nick_error || this.f['nick'].errors || this.f['telegram'].errors || this.f['pwd'].errors || this.f['pwd_confirm'].errors) {
                this.tab = 'tab1'
            } else {
                this.tab = 'tab2'
            }
            return;
        }

        this.sigining = true;
        this.apiService.post('/auth/signup-secret', this.siginUpForm.value).subscribe({
            next: (v) => {
                if(v.success) {
                    this.sigining = false;
                    this.onClose.emit('success')
                    // this.router.navigateByUrl(this.returnUrl);
                    this.modalService.open(null, 'message', { title: `회원가입`, message: v.message })
                } else {
                    this.raiseError(v.message);
                    this.modalService.open(null, 'message', { title: `회원가입 실패`, message: v.message })
                }
            },
            error: (err) => {
                this.raiseError(err.message || err);
                this.modalService.open(null, 'message', { title: `회원가입 실패`, message: err.message || err })
            },
        })
    }

    GoModal(next:string, $event:any = null): void {
        if($event) $event.preventDefault()

        this.onClose.emit()
        this.modalService.open(null, next)
    }

    raiseError(error:any) {
        this.error = error;
        this.sigining = false;
    }

    onDuplicate(kind:string) {
        let value:string=''
        if(kind =='uid') {
            const uid = this.f['uid'].value.replace(/\s+/g, '')
            if(!uid) {
                this.uid_error = `아이디를 번호를 입력하세요.`
                return;
            }

            value = uid;
            this.onDuplicating_uid = true;
        } else if(kind == 'nick') {
            const nick = this.f['nick'].value.replace(/\s+/g, '')
            if(!nick) {
                this.nick_error = `닉네임을 입력하세요.`
                return;
            }

            value = nick;
            this.onDuplicating_nick = true;
        }
        
        this.apiService.post('/auth/duplicate', { kind: kind, value: value }).subscribe({
            next: (jsondata) => {
                if(kind =='uid') this.onDuplicating_uid = false
                else if(kind == 'nick') this.onDuplicating_nick = false;

                if(jsondata) {
                    if(!jsondata.success) {
                        if(kind =='uid') this.uid_error = jsondata.message
                        else if(kind == 'nick') this.nick_error = jsondata.message
                    } else {
                        if(kind =='uid') {
                            this.uid_error = null;
                            if(jsondata.message) this.ok.uid = jsondata.message
                        } else if(kind =='nick') {
                            this.nick_error = null;
                            if(jsondata.message) this.ok.nick = jsondata.message
                        }
                        // this.duplicated = true;
                        // (window as any).alert(jsondata.message)
                    }
                }
            }, 
            error: (e) => {
                if(kind =='uid') this.onDuplicating_uid = false
                else if(kind == 'nick') this.onDuplicating_nick = false;
            }
        })
    }

    selected_crypto_code(value:string) {
        this.crypto_networks.splice(0)
        if(value) {
            this.crypto_items.map((v:any) => {
                if(v.code == value) this.crypto_networks.push({ code: v.network, value: v.network })
            })
        }
        this.f['crypto_code'].patchValue(value)
    }
    
    change_code($event:any, item:any) {
        const el = $event.target
        const parent = el.closest('.ng-select2'); // style.scss
        if(parent) parent.classList.remove('on')
        
        const value = item['code']
        this.crypto_networks.splice(0)
        if(value) {
            this.crypto_items.map((v:any) => {
                if(v.code == value) this.crypto_networks.push({ code: v.network, value: v.network })
            })
        }
    }

    change_network($event:any, item:any) {
        const el = $event.target
        const parent = el.closest('.ng-select'); // style.scss
        if(parent) parent.classList.remove('on')

        const value = item['code']
        if(value) {
            const item = this.crypto_items.find((v:any)=> v.network == value)
            if(item) {
                this.siginUpForm.controls['crypto_network'].patchValue(item.network);
                this.siginUpForm.controls['crypto_dcode'].patchValue(item.dcode);
                return;
            }
        }

        this.siginUpForm.controls['dcode'].patchValue('')
    }
}
 