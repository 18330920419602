import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, HostListener, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '../../common/modal/modal.service';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { SwiperNoticeComponent } from '../home/swiper-notice.component';
import { LoadingComponent } from "../../common/loading/loading.component";
import { ContestComponent } from '../home/contest.component';
import { CasinoListComponent } from './casino-list.component';
import { toCamel, toSnake } from '@app/shared/lib/common';
import { ImgxDirective } from '../../common/imgx.directive';


@Component({
    selector: 'app-casino',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SwiperDirective,
        SwiperNoticeComponent,
        LoadingComponent,
        ContestComponent,
        CasinoListComponent,
        ImgxDirective
    ],
    templateUrl: './casino.component.html',
    styles: ``,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CasinoComponent {
    private subs: Subscription[] = [];

    @Output() onSearchBtn: EventEmitter<string> = new EventEmitter<string>();

    swiperBaseOptions:SwiperOptions = {
        loop:false,
        slidesPerView: 3,
        spaceBetween: 10,
        autoplay: false,
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            815:{
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
            990:{
                slidesPerView: 5,
                slidesPerGroup: 5,
            },
            1100:{
                slidesPerView: 6,
                slidesPerGroup: 6,
            },
            1335:{
                slidesPerView: 7,
                slidesPerGroup: 7,
            }
        },
        injectStyles: [`
            :host {
                --swiper-theme-color: #fff;
                --swiper-navigation-size: 18px;
                --swiper-pagination-bottom: 0px;
            }
        `]
    };

    swiperItems:any = [
        { code:'liveCasino', name:'라이브 카지노', items:[], swiperOptions:<SwiperOptions>{} },
        { code:'koreanDealer', name:'코리안 딜러', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'tvShowGame', name:'TV쇼 게임', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'vipSalon', name:'VIP 살롱', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'baccarat', name:'바카라', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'holdemPoker', name:'홀덤 & 포커', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'roulette', name:'룰렛', items:[],swiperOptions:<SwiperOptions>{} },
        { code:'blackjack', name:'블랙잭', items:[],swiperOptions:<SwiperOptions>{} },
    ]


    tab:string = 'home'

    get modal() { return this.modalService }
    constructor(
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private router: Router,
        private apiService: ApiService,
    ){}

    ngOnInit(): void {
        this.swiperItems.map((it:any) => {
            it.swiperOptions = { 
                ...this.swiperBaseOptions, 
                navigation: {
                    nextEl: `.${it.code} .swiper-next`,
                    prevEl: `.${it.code} .swiper-prev`,
                }
            }
        })

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngAfterViewInit() {
    }

    fnGetData() {
        this.apiService.post('/casino/home', {}).subscribe({
            next: (jsondata:any) => {
                if(jsondata.success) {
                    for (const [key, value] of Object.entries(jsondata.data)) {
                        const item = this.swiperItems.find((it:any) => it.code === key)
                        item.items = value
                    }
                }
            }, error: (err) => {
                
            }
        })
    }


    onSearch() {
        this.onSearchBtn.emit('casino')
        window.scrollTo(0, 0)
    }

    onTab(tab:string){
        this.tab = toSnake(tab);
        window.scrollTo(0, 0)
    }
}
