<ng-template [ngIf]="isLoading">
    <app-loading />
</ng-template>
<div class="overflow-y-auto scrollbar h-auto sm:h-[680px] text-sub">
    <div class="leading-relaxed p-6 sm:px-10" >
        <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
            <div class="modal-tab-wrap">
                <!-- 입금 -->
                <div class="deposit-detail">
                    <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress">
                        <div class="flex-1">입금신청이 진행중 입니다.</div>
                        <!-- <button type="button" class="btn btn-purple2 p-1" (click)="deposit_cancel()">취소하기</button> -->
                    </div>
                    <div class="mt-3">입금계좌는 수시로 변경되므로, 충전시 마다 확인해주셔야 합니다!</div>
                    <p>
                        계좌번호 확인을 원하시면 비밀번호를 입력하고 <b class="text-tit">[입금계좌확인]</b> 버튼을 눌러주세요.
                    </p>
                    <div class="flex items-center justify-between bg-modaldark mt-3">
                        <div class="flex flex-1 items-center text-base">
                            <input type="password" formControlName="pwd" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="비밀번호" autocomplete="false" />
                        </div>
                        <button type="button" class="btn-yellow font-bold p-1" (click)="fnGetAccount()">입금계좌확인</button>
                    </div>
                    <div *ngIf="submitted2 && f['pwd'].errors" class="invalid-feedback">
                        <div *ngIf="f['pwd'].errors['check_error']">{{f['pwd'].errors['check_error']}}</div>
                    </div>
                    <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                        <div class="flex items-center gap-4 text-base">
                            <p class="pl-4 text-tit">₩</p>
                            <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" (keyup)="fnSetBonus()" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" numbersOnly />
                        </div>
                        <div class="text-primary">추가 +₩{{ bonus_amount | number }}</div>
                    </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                        <div *ngIf="f['amount'].errors['required']">금액을 입력하세요.</div>
                        <div *ngIf="f['amount'].errors['check_error']">{{f['amount'].errors['check_error']}}</div>
                    </div>
                    <div class="flex items-center mt-3">
                        <div class="flex items-center gap-2">
                            <label class="form-check-label ml-0" for="wallet-0" *ngIf="bonus_rate"><b class="text-tit">{{ deposit_rolling_text }}</b> 이상 롤링에 동의하며 <span class="text-tit">{{ bonus_rate | number:'1.0-2' }}% (최대:{{ deposit_mileage_max | number }}원)</span> 보너스를 받겠습니다. </label> 
                            <label class="form-check-label ml-0" for="wallet-0" *ngIf="!bonus_rate"><b class="text-tit">{{ deposit_rolling_text }}</b>이상 롤링에 동의하며 보너스를 받지 않겠습니다. </label> 
                            <div class="form-check form-switch"> 
                                <input formControlName="is_bonus_rolling" class="form-check-input small_check" type="checkbox" (change)="roll_change_check()"> 
                            </div>
                        </div>
                        <div class="tooltip_custom ml-1" *ngIf="is_first_deposit && first_deposit_mileage_rate > 0 && f['is_bonus_rolling'].value == true">
                            <svg class="inline-flex w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                            <div class="hover_box top w-96 shadow p-2 rounded bg-back">
                                <!-- <p class="text-tit">입금 보너스는 프로모션 페이지에서 신청 바랍니다.</p> -->
                                <p class="text-tit">입금 보너스 {{ deposit_mileage_rate | number:'1.0-2'}}%, 첫 충전 프로모션 {{ first_deposit_mileage_rate |number:'1.0-2' }}%</p>
                            </div>
                        </div>
                    </div>
                    <div class="cash_btn_box mt-2">
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                        <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0); bonus_amount=0">초기화</button>
                    </div>

                    <div class="mt-3" *ngIf="is_show_charge">입금 계좌</div>
                    <div class="mt-1 pl-2 text-tit" *ngIf="is_show_charge">
                        <span>{{ charge_bank_name }} {{ charge_bank_account }} {{ charge_bank_owner }}</span>
                    </div>
                    <div class="text-center mt-5">
                        <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress">입금 신청</button>
                    </div>
                    <div class="mt-5">
                        <a href="javascript:;" (click)="GoPromotion($event);">
                            <img src="/assets/custom_img/anonymous_registration.png">
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>