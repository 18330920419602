<div class="container_wrap">
    <ng-template [ngIf]="!currentUser">
        <div class="container">
            <!-- 보너스 -->
            <div class="flex flex-wrap gap-2 w-full items-center justify-between py-5">
                <div class="text-tit text-lg md:text-2xl font-bold">보너스</div>
                <div class="flex items-center md:w-auto w-full">
                    <div class="w-full md:w-72">
                        <input type="text" class="form-control form-control-rounded nofocus bg-back2" placeholder="여기에서 보너스 코드를 사용하세요">
                    </div>
                    <button class="shrink-0 bg-primary rounded w-40 md:w-52 h-10 md:h-11 text-white font-bold">보너스 코드 사용</button>
                </div>
            </div>
    
            <div class="flex flex-wrap justify-between items-center md:gap-y-0 gap-y-4 bg-stand p-6">
                <div class="flex items-center w-full md:w-auto gap-3 pr-10 border-r border-slate-300 border-opacity-40">
                    <img class="w-24" src="/assets/custom_img/bonus/money.webp" alt="">
                    <div>
                        <p>총 보너스 수령</p>
                        <b class="text-2xl text-primary font-bold">$ 5.00</b>
                    </div>
                </div>
                <div class="md:w-auto w-1/2">
                    <p>총 VIP 보너스</p>
                    <b class="text-tit text-base font-bold">$ 0.00</b>
                </div>
                <div class="md:w-auto w-1/2">
                    <p>총 스페셜 보너스</p>
                    <b class="text-tit text-base font-bold">$ 0.00</b>
                </div>
                <div class="md:w-auto w-1/2">
                    <p>총 일반 보너스</p>
                    <b class="text-tit text-base font-bold">$ 5.00</b>
                </div>
                <button class="md:w-auto w-1/2 btn-normal font-bold py-4 p-5 rounded" (click)="modal.open($event, 'bonus-info')">자세히</button>
            </div>
            
            <!-- 일반 보너스 -->
            <div class="flex flex-wrap gap-2 w-full items-center justify-between py-5">
                <div class="text-tit text-lg font-bold">일반 보너스</div>
            </div>
    
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 vip_bonus">
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center justify-between gap-1 text-tit text-lg font-bold">
                            입금 보너스
                            <button class="text-primary text-xs" (click)="modal.open($event, 'bonus-deposit')">상세 보기 <svg class="inline-flex w-3 h-3 mb-0.5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                        </h6>
                        <div class="w-full text-xs mt-2">
                            <div class="flex justify-between items-center p-1 bg-back rounded">
                                <span class="text-tit">1st 보너스</span>
                                <p class="text-right text-sm font-bold text-tit">180%</p>
                            </div>
                            <div class="flex justify-between items-center p-1">
                                <span>2nd 보너스</span>
                                <p class="text-right">240%</p>
                            </div>
                            <div class="flex justify-between items-center p-1">
                                <span>3rd 보너스</span>
                                <p class="text-right">300%</p>
                            </div>
                            <div class="flex justify-between items-center p-1">
                                <span>4th 보너스</span>
                                <p class="text-right">360%</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-20 mt-auto">
                        <button class="w-full btn-green h-12 font-bold text-primary" (click)="modal.open($event, 'deposit')">
                            첫번쨰 충전
                        </button>
                    </div>
                </div>
    
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            퀘스트
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    계속해서 퀘스트를 완료하고 더 많은 보상을 받으세요.
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">일일 및 주간 퀘스트 완료</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>일일 퀘스트</span>
                                <p class="text-right">0 / 3</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>주간 미션</span>
                                <p class="text-right">0 / 1</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-20 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold text-primary" (click)="modal.open($event, 'quest')">
                            완료한 퀘스트
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_6">
                        <img class="relative z-10 w-[90px] mb-12" src="/assets/custom_img/bonus/quests-1.webp" alt="">
                    </div>
                </div>
    
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            럭키 스핀
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    행운의 스핀을 얻는 방법에는 두 가지가 있습니다.
                                    <ul class="list-disc pl-4">
                                        <li>특정 베팅 금액에 도달하면 매일 1회의 무료 스핀을 받게 됩니다.</li>
                                        <li class="mt-1">VIP 레벨 8부터 레벨이 오를 때마다 무료 스핀을 1회 받게 되며 획득할 수 있는 스핀 수에는 제한이 없습니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>가능 스핀</span>
                                <p class="text-right">0</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>일일 스핀</span>
                                <p class="text-right">롤링 Req<br/>$ 0.00 / $ 200.00</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>일일 롤링 만료</span>
                                <p class="text-right">22h:04m:39s</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>VIP 스핀</span>
                                <p class="text-right">도달 VIP 8</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-20 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold text-primary" (click)="modal.open($event, 'spin')">
                            스핀 체크
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_7">
                        <img class="relative z-10 w-[90px] mb-12" src="/assets/custom_img/bonus/spin-1.webp" alt="">
                    </div>
                </div>
    
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            롤
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    롤링 규칙:
                                    <ul class="list-disc pl-4">
                                        <li>각 플레이어는 24시간마다 주사위를 굴릴 기회가 한 번 있습니다.</li>
                                        <li class="mt-1">상금은 매일 2:10(UTC+0)에 추첨됩니다.</li>
                                        <li class="mt-1">ROLL의 포인트는 0-999이며 0이 가장 작고 999가 가장 큽니다. 매일 보상을 위해 상위 10명의 플레이어를 내림차순으로 나열합니다.</li>
                                        <li class="mt-1">서로 다른 플레이어가 같은 포인트를 동시에 굴리면 시간에 따라 순위가 결정됩니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">24시간마다 무료 롤</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>프리 롤</span>
                                <p class="text-right">0</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>총 수령</span>
                                <b class="text-tit text-right">$ 0.00</b>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-20 mt-auto">
                        <button class="w-full btn-normal h-12" (click)="modal.open($event, 'bonus-roll')">
                            <svg class="inline-flex mb-1 w-5 h-5 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Recent"></use></svg>
                            준비완료
                            <span class="text-tit">01h:32m:05s</span>
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_8">
                        <img class="relative z-10 w-[130px] mb-12" src="/assets/custom_img/bonus/roll-1.webp" alt="">
                    </div>
                </div>
    
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            BCD 레이크백
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    <ul class="list-disc pl-4">
                                        <li>잠긴 BCD는 예치금 보너스 및 럭키 스핀과 같은 특정 보너스를 통해 얻습니다.</li>
                                        <li class="mt-1">BCD 잠금 해제는 쉽습니다! 본질적으로 레이크백과 동일하며 베팅을 통해 비례적으로 잠금 해제됩니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">롤링의 추가 보상</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>최소 수령</span>
                                <p class="text-right">5.00 BCD</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>보너스 수령 준비 완료</span>
                                <p class="text-right"><b class="text-tit">0.00</b> BCD</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-20 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold text-primary" (click)="modal.open($event, 'bonus-bcd')">
                            잠금해제 BCD
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_8">
                        <img class="relative z-10 w-[90px] mb-12" src="/assets/custom_img/bonus/rakeback.webp" alt="">
                    </div>
                </div>
    
            </div>
    
            <!-- vip 보너스 -->
            <div class="flex flex-wrap gap-2 w-full items-center justify-between py-5">
                <div class="text-tit text-lg font-bold"><span class="text-yellow">VIP</span> 보너스</div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 vip_bonus">
                
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-10">
                        <h6 class="text-tit text-lg font-bold">레벨 업 보상</h6>
                        <p class="mt-2 text-xs">레벨을 올려 특별한 보상을 받으세요.</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>현재 VIP</span>
                                <b class="text-tit text-right">VIP0</b>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>다음 레벨까지 남은 XP</span>
                                <p class="text-right"><b class="text-tit">0</b> / 1</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>보너스 수령 준비 완료</span>
                                <p class="text-right">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-10 mt-auto">
                        <button class="w-full btn-normal h-12 text-primary font-bold" (click)="openModal($event, 'vip-club')">VIP 클럽 보기</button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_1">
                        <img class="relative z-10 w-[100px] mb-12" src="/assets/custom_img/bonus/levelup.webp" alt="">
                    </div>
                </div>
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            재충전
                            <span class="p-1 text-primary bg-primary bg-opacity-10 rounded text-sm font-normal">시간당</span>
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    지난 7일(24/7) 동안 최소 $1,318,261를 베팅한 경우. 활성화되면 재충전은 다음 7일 동안 사용할 수 있습니다.
                                </div>
                            </div>
                        </h6>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>롤링 필요</span>
                                <p class="text-right">$1,318,261</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>현재 등급</span>
                                <p class="text-right">-</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>재충전 속도</span>
                                <p class="text-right">-</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>보너스 수령 준비 완료</span>
                                <p class="text-right">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-10 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold">
                            <svg class="inline-flex mb-1 w-5 h-5 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_VipClub"></use></svg>
                            VIP22에 획득 가능
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_2">
                        <img class="relative z-10 w-[100px] mb-12" src="/assets/custom_img/bonus/recharge-1.webp" alt="">
                    </div>
                </div>
                
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            주간 캐쉬백
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    <ul class="list-disc pl-4">
                                        <li>주간 캐쉬백은 최근 활동을 기반으로 개인화된 보너스입니다.</li>
                                        <li class="mt-1">우리는 매주 금요일 주간 보너스를 드랍하는 것을 목표로 합니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">매주 금요일에 수령</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>롤링 필요</span>
                                <p class="text-right">$1,318,261</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>지난 주간 보너스 수령하기</span>
                                <p class="text-right">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-10 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold">
                            <svg class="inline-flex mb-1 w-5 h-5 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_VipClub"></use></svg>
                            VIP22에 획득 가능
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_3">
                        <img class="relative z-10 w-[90px] mb-12" src="/assets/custom_img/bonus/weekly-1.webp" alt="">
                    </div>
                </div>
                
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            월간 캐쉬백
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    <ul class="list-disc pl-4">
                                        <li>월간 캐쉬백은 최근 활동을 기반으로 한 맞춤형 보너스입니다.</li>
                                        <li class="mt-1">매월 15일마다 월간 캐쉬백을 지급하는 것을 목표로 합니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">매월 15일 수령</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>롤링 필요</span>
                                <p class="text-right">$13,318,261</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>지난 월간 보너스 수령하기</span>
                                <p class="text-right">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-10 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold">
                            <svg class="inline-flex mb-1 w-5 h-5 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_VipClub"></use></svg>
                            VIP22에 획득 가능
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_4">
                        <img class="relative z-10 w-[90px] mb-12" src="/assets/custom_img/bonus/monthly.webp" alt="">
                    </div>
                </div>
    
                <div class="relative flex flex-col h-60 p-5 bg-stand overflow-hidden">
                    <div class="relative z-20">
                        <h6 class="flex items-center gap-1 text-tit text-lg font-bold">
                            스포츠 주간 캐쉬백
                            <div class="tooltip_custom">
                                <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg>
                                <div class="hover_box z-20 left_side w-72 text-xs shadow p-2 rounded bg-modaldark font-normal text-basic">
                                    <ul class="list-disc pl-4">
                                        <li><span>7d 스포츠 베팅:</span><br><span>$659,130-$3,294,335 =$6,591 보너스</span><br><span>$3,295,653-$6,589,989 =$39,547 보너스</span><br><span>$6,591,307-$13,181,297 =$92,278 보너스</span><br><span>$13,182,615+ =$197,739 보너스</span></li>
                                        <li class="mt-1">배팅 기간 토요일 00:00 ~ 금요일 23:59 (7일)</li>
                                        <li class="mt-1">우리는 매주 토요일 주간 캐쉬백을 떨어뜨리는 것을 목표로 합니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </h6>
                        <p class="mt-2 text-xs">매주 토요일에 수령</p>
                        <div class="w-2/3 text-xs mt-4">
                            <div class="flex justify-between items-center">
                                <span>롤링 필요</span>
                                <p class="text-right">$659,261</p>
                            </div>
                            <div class="flex justify-between items-center mt-1">
                                <span>지난 주간 보너스 수령하기</span>
                                <p class="text-right">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="relative z-10 mt-auto">
                        <button class="w-full btn-normal h-12 font-bold">
                            <svg class="inline-flex mb-1 w-5 h-5 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_VipClub"></use></svg>
                            VIP22에 획득 가능
                        </button>
                    </div>
                    <div class="absolute right-0 top-0 h-full flex items-center bg_box bg_5">
                        <img class="relative z-10 w-[80px] mb-12" src="/assets/custom_img/bonus/sports.webp" alt="">
                    </div>
                </div>
    
            </div>
    
            <!-- 스페셜 보너스 -->
            <div class="flex flex-wrap gap-2 w-full items-center justify-between py-5">
                <div class="text-tit text-lg font-bold">스페셜 보너스</div>
            </div>
            <div class="flex flex-col justify-center text-center py-10 bg-stand mb-20">
                <img class="w-48 mx-auto" src="/assets/custom_img/empty.webp">
                <div class="-mt-5 text-basic opacity-70">웁스! 아직 데이터가 없습니다!</div>
            </div>
    
           
    
    
        </div>
    </ng-template>

    <ng-template [ngIf]="!currentUser">
        <div class="bonus_visual">
            <div class="container">
                <div class="px-10 py-5 md:pt-6 pt-72">
                    <h3 class="text-tit text-3xl md:text-4xl font-extrabold md:text-left text-center"><span class="text-yellow">무한한</span> 혜택을 누려보세요</h3>
                    <p class="text-tit font-bold md:text-left text-center">지금 가입하고 행운과 보상의 짜릿한 여정을 시작하세요!</p>

                    <div class="block md:hidden text-center mt-5">
                        <button class="btn-purple w-60 h-12 font-bold" (click)="modal.open($event, 'sign-up')">지금 KBC GAME에 가입하세요</button>
                    </div>

                    <div class="flex items-center gap-3 mt-10">
                        <img class="w-10" src="/assets/custom_img/bonus/bonus.webp" alt="">
                        <div class="md:text-sm text-xs">
                            <p class="text-tit font-bold">레벨업 보너스</p>
                            <p>레벨업 보너스로 새로운 높이에 도달하세요</p>
                        </div>
                    </div>

                    <div class="flex items-center gap-3 mt-5">
                        <img class="w-10" src="/assets/custom_img/bonus/recharge.webp" alt="">
                        <div class="md:text-sm text-xs">
                            <p class="text-tit font-bold">재충전</p>
                            <p>원하는 대로 재충전 보너스를 예약하세요</p>
                        </div>
                    </div>

                    <div class="flex items-center gap-3 mt-5">
                        <img class="w-10" src="/assets/custom_img/bonus/weekly.webp" alt="">
                        <div class="md:text-sm text-xs">
                            <p class="text-tit font-bold">주간 및 월간 캐쉬백</p>
                            <p>매혹적인 주간 및 월간 보너스로 파워 업</p>
                        </div>
                    </div>
                    <div class="hidden md:block text-center mt-5">
                        <button class="btn-purple w-60 h-12 font-bold" (click)="modal.open($event, 'sign-up')">지금 KBC GAME에 가입하세요</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="flex items-center md:flex-row flex-col gap-1 justify-around py-6">
                <div class="flex items-center gap-3 text-sm md:text-lg text-tit font-extrabold">
                    <span class="flex items-center justify-center w-7 h-7 md:w-10 md:h-10 rounded-full border border-purple-300 border-opacity-40 bg-gradient-purple2 text-white font-extrabold text-xs md:text-sm">01</span>
                    가입 및 입금
                </div>
                <div class="flex items-center gap-3 text-sm md:text-lg text-tit font-extrabold">
                    <span class="flex items-center justify-center w-7 h-7 md:w-10 md:h-10 rounded-full border border-purple-300 border-opacity-40 bg-gradient-purple2 text-white font-extrabold text-xs md:text-sm">02</span>
                    좋아하는 게임을 플레이하세요
                </div>
                <div class="flex items-center gap-3 text-sm md:text-lg text-tit font-extrabold">
                    <span class="flex items-center justify-center w-7 h-7 md:w-10 md:h-10 rounded-full border border-purple-300 border-opacity-40 bg-gradient-purple2 text-white font-extrabold text-xs md:text-sm">03</span>
                    VIP 레벨을 높이세요
                </div>
            </div>

            <div class="flex items-center md:flex-row flex-col gap-1 ">
                <img class="w-full md:w-1/2" src="/assets/custom_img/bonus/step1.webp" alt="">
                <div class="w-full md:w-1/2 md:pr-[10%] md:text-left text-center">
                    <h5 class="text-tit text-lg md:text-2xl font-extrabold">KBC GAME의 탁월한 입금 보너스를 알아보세요</h5>
                    <p class="mt-4 mb-3 md:my-8 font-semibold">우리는 당신이 당신의 초기 입금 보너스를 바로 볼 수 있고 당신의 첫 입금에 대해 180% 보너스를 제공한다는 점에서 독특합니다!</p>
                    <button class="btn-green h-11 px-8 font-bold" (click)="modal.open($event, 'sign-up')">입금 보너스 청구</button>
                </div>
            </div>

            <div class="flex items-center md:flex-row-reverse flex-col gap-1 ">
                <img class="w-full md:w-1/2" src="/assets/custom_img/bonus/step2.webp" alt="">
                <div class="w-full md:w-1/2 md:pl-[10%] md:text-right text-center">
                    <h5 class="text-tit text-lg md:text-2xl font-extrabold">레벨업 보너스로 더 고렙에 도달하세요</h5>
                    <p class="mt-4 mb-3 md:my-8 font-semibold">레벨을 올리면 여러 번 잠금 해제되는 놀라운 보상으로 지금 재정을 강화하고 전례 없는 수준으로 균형을 강화하세요.</p>
                    <button class="btn-green h-11 px-8 font-bold" (click)="modal.open($event, 'sign-up')">레벨 업 보너스 잠금 해제</button>
                </div>
            </div>

            <div class="flex items-center md:flex-row flex-col gap-1 ">
                <img class="w-full md:w-1/2" src="/assets/custom_img/bonus/step3.webp" alt="">
                <div class="w-full md:w-1/2 md:pr-[10%] md:text-left text-center">
                    <h5 class="text-tit text-lg md:text-2xl font-extrabold">BCD/FIAT 레이크백의 잠재력 극대화</h5>
                    <p class="mt-4 mb-3 md:my-8 font-semibold">보너스를 통해 잠긴 BCD/법정 화폐를 획득하고 원하는 방식으로 베팅하여 손쉽게 잠재력을 잠금 해제하세요. 잔액에 직접 청구하십시오!</p>
                    <button class="btn-green h-11 px-8 font-bold" (click)="modal.open($event, 'sign-up')">어떻게 레이크백을 잠금해제 하나요?</button>
                </div>
            </div>


            <div class="mt-10">
                <h4 class="mb-6 text-center text-tit text-xl md:text-2xl font-extrabold">다양한 <b class="text-yellow">무료</b> 혜택을 누리세요</h4>
                <div class="flex md:flex-row flex-col bonus_free_box gap-5">
                    <div class="relative quest w-full md:w-1/3 h-[256px] flex items-end justify-start p-4 rounded-lg overflow-hidden">
                        <span class="absolute left-0 top-0 py-2 px-6 rounded-br-lg rounded-tl-lg text-base font-bold">매일 및 매주</span>
                        <div class="img_box">
                            <img src="/assets/custom_img/bonus/quests.webp" alt="">
                        </div>
                        <div class="w-4/5">
                            <h4 class="text-tit font-extrabold text-2xl">퀘스트 허브</h4>
                            <p class="mt-4">수입을 모으고 눈덩이처럼 불어나게 하면서 일일 및 주간 퀘스트를 정복하세요!</p>
                        </div>
                    </div>
                    <div class="relative spin w-full md:w-1/3 h-[256px] flex items-end justify-start p-4 rounded-lg overflow-hidden">
                        <span class="absolute left-0 top-0 py-2 px-6 rounded-br-lg rounded-tl-lg text-base font-bold">매일</span>
                        <div class="img_box">
                            <img src="/assets/custom_img/bonus/spin.webp" alt="">
                        </div>
                        <div class="w-4/5">
                            <h4 class="text-tit font-extrabold text-2xl">무료 럭키 스핀</h4>
                            <p class="mt-4">회전하고 매일 최대 5 BTC를 획득할 수 있는 기회를 얻으세요 - 모든 롤에서 최고의 스릴!</p>
                        </div>
                    </div>
                    <div class="relative roll w-full md:w-1/3 h-[256px] flex items-end justify-start p-4 rounded-lg overflow-hidden">
                        <span class="absolute left-0 top-0 py-2 px-6 rounded-br-lg rounded-tl-lg text-base font-bold">매일</span>
                        <div class="img_box">
                            <img src="/assets/custom_img/bonus/roll.webp" alt="">
                        </div>
                        <div class="w-4/5">
                            <h4 class="text-tit font-extrabold text-2xl">프리롤 대회</h4>
                            <p class="mt-4">같은 점수를 빨리 얻을수록 순위가 높아집니다. 게임을 시작하세요!</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mt-4 p-4 md:p-10 bonus_cashback">
                <div class="relative flex items-center md:flex-row flex-col gap-1 ">
                    <div class="flex items-center justify-center w-full md:w-3/5">
                        <img class="lg:w-[110%] max-w-[110%]" src="/assets/custom_img/bonus/cashback.webp" alt="">
                    </div>
                    <div class="w-full md:w-2/5 md:text-left text-center -mt-20">
                        <h5 class="text-tit text-lg md:text-2xl font-extrabold">BC게임의 <b class="text-yellow">충전</b> 캐쉬백 소개</h5>
                        <p class="mt-4 mb-3 md:my-8 font-semibold">힘이 당신의 손에 있는 짜릿한 Recharge의 세계에 빠져보세요. KBC GAME에 가입하고 보상을 맞춤화하세요.</p>
                        <a class="text-primary" (click)="modal.open($event, 'sign-up')">
                            지금 KBC GAME에 가입하세요
                            <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                        </a>
                    </div>
                </div>
                <div class="relative z-20 w-full flex flex-col md:flex-row items-center mt-4 md:-mt-8 lg:-mt-32 cashback_box px-4 md:px-1 xl:py-0 py-3 border border-slate-300 border-opacity-50 rounded-lg ">
                    <div class="relative w-full md:w-1/3 flex flex-row md:flex-col xl:flex-row items-center gap-4 px-4 md:py-0 py-3">
                        <i class="absolute right-0 bottom-0 md:top-1/2 w-full md:w-[1px] h-[1px] md:h-1/2 md:-translate-y-1/2 bg-slate-300"></i>
                        <div class="relative flex items-center justify-center w-20 md:w-32 h-full xl:py-8">
                            <div class="cashback_bg_1 absolute left-0 top-0 w-full h-full"></div>
                            <img class="relative z-10 w-2/3 xl:w-1/2" src="/assets/custom_img/bonus/cashback1.webp" alt="">
                        </div>
                        <div class="flex-1 xl:text-left md:text-center text-left">
                            <h6 class="text-base font-semibold text-tit">재충전 활성화</h6>
                            <p class="text-xs mt-2">VIP 레벨 22 이상에 도달하기 위해 노력하면서 재충전 활성화를 향한 여정.</p>
                        </div>
                    </div>
                    <div class="relative w-full md:w-1/3 flex flex-row md:flex-col xl:flex-row items-center gap-4 px-4 md:py-0 py-3">
                        <i class="absolute right-0 bottom-0 md:top-1/2 w-full md:w-[1px] h-[1px] md:h-1/2 md:-translate-y-1/2 bg-slate-300"></i>
                        <div class="relative flex items-center justify-center w-20 md:w-32 h-full xl:py-8">
                            <div class="cashback_bg_2 absolute left-0 top-0 w-full h-full"></div>
                            <img class="relative z-10 w-2/3 xl:w-1/2" src="/assets/custom_img/bonus/cashback2.webp" alt="">
                        </div>
                        <div class="flex-1 xl:text-left md:text-center text-left">
                            <h6 class="text-base font-semibold text-tit">원하는 속도로 재충전하세요</h6>
                            <p class="text-xs mt-2">10분마다, 매시간 또는 매일 보너스를 청구하세요</p>
                        </div>
                    </div>
                    <div class="w-full md:w-1/3 flex flex-row md:flex-col xl:flex-row items-center gap-4 px-4 md:py-0 py-3">
                        <div class="relative flex items-center justify-center w-20 md:w-32 h-full xl:py-8">
                            <div class="cashback_bg_3 absolute left-0 top-0 w-full h-full"></div>
                            <img class="relative z-10 w-2/3 xl:w-1/2" src="/assets/custom_img/bonus/cashback3.webp" alt="">
                        </div>
                        <div class="flex-1 xl:text-left md:text-center text-left">
                            <h6 class="text-base font-semibold text-tit">충전 보상 등급</h6>
                            <p class="text-xs mt-2">내기를 걸고 1단계에서 4단계로 올라갈 때 보상을 눈덩이처럼 굴리세요</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="py-12 bonus_benefit">
                <div class="text-center text-tit font-extrabold text-lg md:text-2xl">기다리다. <b class="text-yellow">더 많은</b> 혜택이 있습니다!</div>
                <div class="flex items-center justify-between flex-col md:flex-row md:gap-0 gap-5 mt-4">
                    <div class="w-full md:w-1/3 text-center px-5 border-r border-slate-300 border-opacity-40">
                        <div class="relative bg_1">
                            <img class="relative z-10 w-1/2 md:w-2/3 mx-auto " src="/assets/custom_img/bonus/benefit1.webp" alt="">
                        </div>
                        <h6 class="mt-2 text-lg md:text-xl text-tit font-extrabold">놀라운 보상을 위해 코코를 잡아라!</h6>
                        <p class="mt-5 md:text-sm text-xs">페이지에서 6시간마다 지나가는 COCO 거미를 잡고 VIP 레벨에 따라 독점 BCD/Fiat 보상을 받으세요.</p>
                    </div>
                    <div class="w-full md:w-1/3 text-center px-5 border-r border-slate-300 border-opacity-40">
                        <div class="relative bg_2">
                            <img class="relative z-10 w-1/2 md:w-2/3 mx-auto " src="/assets/custom_img/bonus/benefit2.webp" alt="">
                        </div>
                        <h6 class="mt-2 text-lg md:text-xl text-tit font-extrabold">KBC GAME 복권으로 승리</h6>
                        <p class="mt-5 md:text-sm text-xs">증명할 수 있는 공정한 게임을 경험하고 행운의 숫자가 마술처럼 작동하도록 하십시오. 기다리지 말고 뛰어들어 행운을 시험해보세요!</p>
                    </div>
                    <div class="w-full md:w-1/3 text-center px-5">
                        <div class="relative bg_3">
                            <img class="relative z-10 w-1/2 md:w-2/3 mx-auto " src="/assets/custom_img/bonus/benefit3.webp" alt="">
                        </div>
                        <h6 class="mt-2 text-lg md:text-xl text-tit font-extrabold">COIN-DROP 및 CHAT RAIN 대기</h6>
                        <p class="mt-5 md:text-sm text-xs">Constant Chat Rains 및 Coin-Drops를 사용하여 참여하고, 채팅하고, 자발적인 보상을 획득하세요. 활발하고 빠르게 활동하고 무료 코인을 받으세요.</p>
                    </div>
                </div>
            </div>

            <div class="relative mb-10 text-center dark:bg-[#2b2d33] bg-white py-20 bonus_bottom overflow-hidden">
                <p class="relative z-10 text-tit text-lg md:text-2xl font-extrabold break-keep">가장 고급스러운 게임 경험을 제공하게 된 것을 자랑스럽게 생각합니다.</p>
                <button class="btn-green relative z-10 w-72 h-14 mt-8 font-extrabold">즉시 가입</button>
            </div>







        </div>
    </ng-template>
    <!-- ** 푸터 시작 ** -->
    <!-- ** 푸터 끝 ** -->

</div>

