import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse, HttpStatusCode, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { BehaviorSubject, EMPTY, NEVER, Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../app/auth/auth.service';
import { environment } from '../environments/environment';

export const ErrorInterceptor:HttpInterceptorFn = (request:HttpRequest<unknown>, next:HttpHandlerFn):Observable<HttpEvent<unknown>> => {
    const router = inject(Router)
    const cookieService = inject(CookieService)
    const authService = inject(AuthService)

    const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    let isRefreshing:boolean = false;

    const isSigninUrl = request.url.startsWith(environment.apiUrl + '/auth/signin');
    const isSignOutUrl = request.url.startsWith(environment.apiUrl + '/auth/signout');
    const isUpdateTokenUrl = request.url.startsWith(environment.apiUrl + '/auth/update-token');
    const is2faUrl = request.url.startsWith(environment.apiUrl + '/auth/signin-2fa');

    const handle406Error:HttpInterceptorFn = (request:HttpRequest<unknown>, next:HttpHandlerFn):Observable<HttpEvent<unknown>> => {
        if(!isRefreshing) {
            isRefreshing = true;
            refreshTokenSubject.next(null);
            const token = cookieService.get('refresh_atoken')
            if(!token) {
                sessionStorage.setItem('signout', JSON.stringify({ message: `REFRESH_TOKEN ERROR` }));
                router.navigate(['signout']);
                return throwError(() => `REFRESH_TOKEN ERROR`)
            }

            authService.updateToken().subscribe({
                next: (jsondata) => {
                    refreshTokenSubject.next(jsondata.data.access_token);
                }, error: (err) => {
                    sessionStorage.setItem('signout', JSON.stringify(err.error));
                    router.navigate(['signout']);
                    return throwError(() => err.error)
                }
            })
        }
    
        return refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => {
                request = request.clone({
                    headers: request.headers.set(`Authorization`,`Bearer ${token}`)
                });
                return next(request)
            })
        )
    }


    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            if([ 401, 403 ].includes(error.status)) {
                sessionStorage.setItem('signout', JSON.stringify(error.error));
                router.navigate(['signout']);
            }
            else if( [ 404 ].includes(error.status)){

            }
            else if([ 406 ].includes(error.status)) {
                // 2fa || update-token throw Error
                if(is2faUrl || isUpdateTokenUrl) {
                    sessionStorage.setItem('signout', JSON.stringify(error.error));
                    router.navigate(['signout']);
                } else{
                    return handle406Error(request, next)
                }
            } 
            else if([ 503].includes(error.status)) {
                router.navigateByUrl('/upcoming')
            }
            else if([ 0 ].includes(error.status)) {
                sessionStorage.setItem('signout', JSON.stringify({ message: `API SERVER DOWN` }));

                // if(!isSigninUrl && !isSignOutUrl) router.navigate(['signout']);
                return throwError(() => `API SERVER DOWN`);
            }

            return throwError(() => error);
        }
    ))
}

export const ErrorInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];

